.styles_modal__gNwvD {
  padding: 0px;
  border-radius:$br-default;
  max-width: 800px;

  img {
    width: 100%;
    display: block;
  }
}
.styles_closeButton__20ID4 {
  top: -43px;
  right: -37px;
  cursor: pointer;


  svg {
    fill: rgba(255, 255, 255, 0.7);
      transition: all .3s;

      &:hover {
          fill: rgb(255, 255, 255);
      }
  }
}

.modal {
  &.modal-sm {

    .styles_modal__gNwvD {
      max-width: 470px;
      width: 100%;
      padding: 40px;
    }
  }
  &.modal-md {

    .styles_modal__gNwvD {
      max-width: 550px;
      width: 100%;
      padding: 40px;
    }
  }
    .styles_closeButton__20ID4 {
        display: none;
    }
}

:not(.modal) {
  >.styles_overlay__CLSq- {
    padding: 50px;
  }
}

.download-btn {
  position: absolute;
  bottom: -50px;
  right: 0;


  .icon, .icon [class^="path"] {
    &:before {
      transition: all .3s;
    }
  }
  &:hover {
    color: $color-primary;

    .icon, .icon [class^="path"] {
      &:before {
        color: $color-primary;
      }
    }
  }

}

