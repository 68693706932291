.radio-btn {
  input[type="radio"] {
    position: absolute;
    opacity: 0;

    &:checked ~ .checkmark {

      &:after {
        opacity: 1;
      }
    }
  }

  .checkmark {
    height: 24px;
    width: 24px;
    background-color: #fff;
    border-radius: 50%;
    display: inline-block;
    position: relative;
    border: 2px solid $color-primary;

    &:after {
      content: '';
      display: block;
      position: absolute;
      border-radius: 50%;
      height: 14px;
      width: 14px;
      top: 3px;
      left: 3px;
      background-color: $color-primary;
      transition: all 0.25s linear;
      -webkit-transition: all 0.25s linear;
      opacity: 0;
    }
  }

  label {
    font-size: $sm;
    font-weight: 700;
    cursor: pointer;

    .icon {
      margin-right: 20px;
    }
  }

  &.radio-right {
    label {
      display: flex;
      align-items: center;
    }

    .checkmark {
      margin-left: auto;
    }

  }
}

.checkbox {
  label {
    cursor: pointer;
  }
  input[type="checkbox"] {
    position: absolute;
    opacity: 0;

    &:checked ~ .checkmark {
      background-color: rgba($color-primary, .2);

      &:after {
        opacity: 1;

      }
    }
  }

  .checkmark {
    height: 18px;
    width: 18px;
    background-color: #fff;
    display: inline-block;
    position: relative;
    border: 2px solid $color-primary;
    border-radius: 2px;
    transition: all 0.2s linear;

    &:after {
      content: '\e93b';
      font-family: $icomoon-font-family;
      display: block;
      position: absolute;
      top: 3px;
      left: 1px;
      font-size: 9px;
      transition: all 0.2s linear;
      opacity: 0;
      color: $color-primary;
    }
  }
}