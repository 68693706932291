.sign-page {
  background: $color-bg url( $images-folder + "/sign-page/sign-bg.svg") no-repeat center;
  background-size: auto;
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;

  button {
    max-width: 100%;
  }
}

.sign-form {
  padding: 25px 80px 60px;

  width: 100%;
  background-color: $color-white;
  border: 1px solid $color-border;
  border-radius: $br-default;

  &.sign-up {
    max-width: 920px;
  }

  &.sign-in {
    max-width: 570px;
  }
}

.sign-header {
  padding-bottom: 20px;
  border-bottom: 1px solid $color-border;
}

.sign-body {
  padding-top: 40px;
}