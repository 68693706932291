#main {
  position: relative;
  &:after {
    content: "";
    transition: all .3s;
  }

  &.overlay {
    overflow: hidden;
    position: relative;

    &:after {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: rgba(0,0,0,.7);
      z-index: 2;
    }

  }
}

#mainDiv {
  position: relative;

  &.overlayDiv {

    &:after {
      content: '';
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      z-index: 3;
    }
  }
}

.page-wrapper {
  min-height: 100vh;
  padding: 70px 0 0 210px;
  background-color: $color-bg;
}

