@media (max-width: $screen-sm-max) {
  .tab-style {
    &.folder-mobile {
      flex-direction: column;
      border: none;

      li {
        width: 100%;
        padding: 10px 20px;
        margin-bottom: 15px;
        border: 1px solid $color-tertiary;
        background-color: rgba($color-tertiary, .04);
        border-radius: $br-default;
        color: $color-text;
        text-align: left;
        display: flex;
        align-items: center;

        .icon {
          margin-right: 30px;
          display: block;
        }

        &.active {
          background-color: $color-tertiary;
          color: $color-white;

          .icon {
            [class^="path"] {
              &:before {
                color: $color-white;
              }
            }
          }
        }
      }
    }
  }
}