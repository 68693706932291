@media (max-width: $screen-sm-max) {
  $step: 0;
  @while $step <= 100 {
    .mt-sm-#{$step} {
      margin-top: #{$step}px;
    }
    .mr-sm-#{$step} {
      margin-right: #{$step}px;
    }
    .mb-sm-#{$step} {
      margin-bottom: #{$step}px;
    }
    .ml-sm-#{$step} {
      margin-left: #{$step}px;
    }
    .my-sm-#{$step} {
      margin-top: #{$step}px;
      margin-bottom: #{$step}px;
    }
    .mx-sm-#{$step} {
      margin-left: #{$step}px;
      margin-right: #{$step}px;
    }
    .m-sm-#{$step} {
      margin: #{$step}px;
    }

    .pt-sm-#{$step} {
      padding-top: #{$step}px;
    }
    .pr-sm-#{$step} {
      padding-right: #{$step}px;
    }
    .pb-sm-#{$step} {
      padding-bottom: #{$step}px;
    }
    .pl-sm-#{$step} {
      padding-left: #{$step}px;
    }
    .py-sm-#{$step} {
      padding-top: #{$step}px;
      padding-bottom: #{$step}px;
    }
    .px-sm-#{$step} {
      padding-left: #{$step}px;
      padding-right: #{$step}px;
    }
    .p-sm-#{$step} {
      padding: #{$step}px;
    }
    $step: $step + 5;
  }
}