.upload-img {
  text-align: center;
  padding: 70px 100px;
}

.image-list {
  display: grid;
  grid-gap: 20px;

  &:not(.with-right-btn) {
    grid-template-columns: repeat(5, 1fr);
    grid-auto-rows: 152px;
  }

  &.with-right-btn {
    grid-template-columns: repeat(5, 1fr);
    grid-auto-rows: 122px;

    .info {
      position: absolute;
      right: 0;
      top: 0;
    }
  }


  &.cursor-pointer {
    .image-list-item {
      cursor: pointer;
    }
  }

  &.selected {
    .image-list-item {

      &:not(.upload) {
        border-color: $color-primary;

        &:after {
          content: "";
          width: 100%;
          @include position(absolute, 0, 0, 0);
          background-color: rgba(0, 0, 0, .4);
          opacity: 0;
          transition: all .3s;
        }

        &.selected-item {
          &:after {
            opacity: 1;
          }

          &:before {
            content: $icon-checked;
            font-family: $icomoon-font-family;
            color: $color-white;
            font-size: 32px;
            position: absolute;
            z-index: 1;
            left: 50%;
            top: 50%;
            transform: translateY(-50%) translateX(-50%);
          }
        }
      }




    }


  }



}


.image-list-item {
  border-radius: $br-default;
  position: relative;
  border: 2px solid transparent;
  transition: all .3s;

  &.default-image {
    background-color: #EEEEEE;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 48px;
    color: $color-text-10;
    background-image: linear-gradient(to right, #EEEEEE 0%, #f6f7f8 20%, #EEEEEE 60%, #EEEEEE 100%);
    background-repeat: no-repeat;
    background-size: 240px;
    position: relative;
    animation: placeholderShimmer 1.5s forwards infinite linear;
    will-change: transform;
  }

  &.upload {
    border: 1px dashed $color-border;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .icon {
      font-size: 42px;
      color: $color-primary;
    }
  }

  &:not(.upload):not(.default-image) {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
  }
}

.default-info {
  display: block;
  height: 21px;
  width: 21px;
  border-radius: 50%;
  background-color: $color-text-10;
}

.image-list-item-action {
  height: 28px;
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  background-color: rgba(#000, .6);
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 10px;


  .icon {
    padding: 3px 4px;

    &.icon-delete {
      cursor: pointer;

      [class^="path"]:before {
        color: $color-white;
      }
    }
  }

  .icon, .icon [class^="path"]:before {
    color: rgba(255, 255, 255, 0.7);
  }
}

.bar-bottom {
   position: fixed;
   left: 210px;
   bottom: 0;
   right: 0;
   padding: 15px 0;
   z-index: 1;

   &.blue-bar {
     background-color: $color-primary;

     button {
       display: flex;
       align-items: center;
       color: $color-white;
       font-weight: 700;
       font-size: $normal;
       margin: 0 50px;
       cursor: pointer;

       .icon {
         span {
           &:before {
             color: $color-white;
           }
         }
       }
     }
   }

  &.grey-bar {
    background-color: $color-grey;
    height: 75px;
  }
}

@keyframes placeholderShimmer {
  0% {
    background-position: -200px 0;
    transform: scale(1);
  }

  50% {
    transform: scale(1.02);
  }

  100% {
    background-position: 200px 0;
    transform: scale(1);
  }
}