.box {
  background-color: $color-white;
  border: 1px solid $color-border;
}

.folder-group {
  display: grid;
  grid-gap: 15px;
  grid-template-columns: repeat(3, calc(33.333% - 10px));
  grid-auto-rows: 42px;
}

.folder {
  border: 1px solid rgba($color-primary, .7);
  background-color: rgba($color-primary, .04);
  border-radius: $br-default;
  display: flex;
  align-items: center;
  padding: 0 20px;
  cursor: pointer;
  .icon {
    margin-right: 30px;
  }

  .folder-name-wrapper {
    display: block;
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .folder-name {
    font-size: $normal;
    @include ellipsis(100%)
  }
}

.img-card-list {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

  .img-card {
    width: 48%;
    padding-bottom: 20px;
    margin-bottom: 25px;
    border-bottom: 1px solid $color-border;
  }
}

.img-card-top {
  display: flex;
  position: relative;

  .img {
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    width: 120px;
    height: 120px;
    border-radius: $br-default;
    position: relative;

    .img-meta {
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      background-color: rgba(0, 0, 0, 0.6);
      padding: 5px;
      text-align: right;
      color: $color-white;
      font-size: $xs;
    }
  }

  .actions {
    position: absolute;
    top: 0;
    right: 0;
  }
}
.img-card-bottom {
  padding-right: 65px;
  position: relative;

  button {
    position: absolute;
    top: 0;
    right: 20px;
    border: 1px solid $color-tertiary;
    border-radius: 2px;
    padding: 0 12px;
    line-height: 25px;
    height: 25px;

    span {
      font-size: 20px;
      color: $color-tertiary;
      display: block;
    }
  }
}

.empty-page {
  height: calc(100vh - 161px);
  display: flex;
  align-items: center;
  justify-content: center;
}

.empty-page-2 {
  height: calc(100vh - 243px);
  display: flex;
  align-items: center;
  justify-content: center;
}

.empty-page-3 {
  height: calc(100vh - 70px);
  display: flex;
  align-items: center;
  justify-content: center;
}


.empty-img {
  width: 120px;

  &.icon {
    font-size: 120px;
  }
}

.empty-tag {
  width: 120px;

  &.icon {
    font-size: 120px;
  }
}

.spinner-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 11;
  //background-color: #fff;

  svg {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
  }
}
