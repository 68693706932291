.stepper {
  .stepper-header {
    padding: 45px 80px 20px;
  }

  .stepper-header-top {
    display: flex;

    li {
      width: 100%;
      position: relative;
      text-align: right;

      &:first-child {
        width: 40px;
      }

      span {
        display: inline-block;
        height: 40px;
        width: 40px;
        line-height: 40px;
        text-align: center;
        font-weight: 700;
        font-size: $md;
        color: $color-white;
        background-color: $color-border;
        border-radius: 50%;
        position: relative;
        z-index: 1;

        &.icon {
          font-size: $sm;
        }
      }

      &:not(:first-child) {
        &:before {
          content: "";
          display: block;
          position: absolute;
          top: 50%;
          left: 0;
          transform: translateY(-50%);
          height: 1px;
          width: calc(100% - 40px);
          background-color: $color-border;
        }
      }

      &.active {
        span {
          background-color: $color-primary;
        }

        &:before {
          background-color: $color-primary;
        }
      }

    }
  }
  .stepper-header-bottom {
    text-align: center;
    padding-top: 35px;

    h4 {
      padding-bottom: 15px;
    }
  }
}

.list-item {
  li {
    transition: all .2s;

    label {
      padding: 25px 20px;
      display: block;
    }

    &:not(:last-child) {
      border-bottom: 1px solid $color-border;
    }

    &.active {
      background-color: $color-primary;
      color: $color-white;

      .checkmark {
        background-color: $color-primary;
        border: 2px solid $color-white;

        &:after {
          background-color: $color-white;
        }
      }
    }
  }
}