.table-style {
  table {
    width: 100%;

    th, td {
      vertical-align: middle;
      padding-left: 10px;
      padding-right: 10px;

      &.text-center {
        text-align: center;
      }
    }
    th {
      text-align: left;
      font-size: $sm;
      font-weight: 700;
      padding-bottom: 11px;
    }

    td {
      font-size: $sm;
      font-weight: 400;
      padding-top: 7px;
      padding-bottom: 7px;
    }

    tbody {
      tr {
        border-bottom: 1px solid $color-border;
      }
    }
  }
  &.with-action {
    table {
      th, td {
        &:last-child {
          text-align: center;
          width: 120px;
        }
      }
    }

  }

  &.user-table {
    table {
      td {

        &:nth-child(1) {
          >div {
            width: 160px;
          }
          width: 120px;
        }

        &:nth-child(2) {
          width: 28%;
         .text-wrapper {
            width: 220px;
          }
        }
        &:nth-child(3) {
          width: 105px;
        }
        &:nth-child(4) {
          width: 15%;
        }
      }
    }
  }

  .scrollarea-content {
    min-width: 830px;
    padding-bottom: 9px;
  }
  .scrollarea {
    .scrollbar-container {
      z-index: 1;
      pointer-events: none;
    }
  }
}
