.textfield {
  position: relative;
  margin-bottom: 28px;

  &.mb-15 {
    margin-bottom: 15px;
  }

  &.no-margin {
    margin-bottom: 0;
  }

  .label {
    font-size: $sm;
    font-weight: 600;
    padding-bottom: 10px;
    display: block;
    color: $color-text-70;
  }

  input[type='text'], input[type='email'], input[type='password'] {
    border-radius: $br-default;
    font-size: $normal;
    border: 1px solid $color-border;
    height: 44px;
    line-height: 44px;
    padding: 12px 20px;
    transition: all .3s;
    width: 100%;
    background-color: transparent;

    &:focus {
      border: 1px solid $color-text;
      opacity: 1;

      & ~ .icon {
        opacity: 1;
      }
    }
  }
  &.with-btn {

    input {
      padding-right: 60px;

      &:focus ~ button {
        border-color: $color-text;
      }
    }
    button {
      position: absolute;
      right: 0;
      height: 100%;
      width: 43px;
      border-radius: 0 $br-default $br-default 0;
      background-color: $color-tertiary;
      color: $color-white;
      border: 1px solid $color-green-dark;
      transition: all .3s;
    }
  }

  &.with-icon  {
    input[type='text'], input[type='email'], input[type='password'] {
      padding-left: 50px;
      padding-right: 20px;
    }
    .icon {
      position: absolute;
      left: 15px;
      bottom: 10px;
      font-size: $lg;
      opacity: .5;
      transition: all .3s;
    }
  }

  &.with-right-icon {
    input[type='text'], input[type='email'], input[type='password'] {
      padding-right: 50px;
      padding-left: 20px;
    }
    .icon {
      position: absolute;
      right: 15px;
      top: 10px;
      font-size: $lg;
      opacity: .5;
      transition: all .3s;
    }
  }

  &.with-dropdown {
    input[type='text'], input[type='email'], input[type='password'] {
      padding-right: 80px;
    }
    .icon-arrow-dropdown {
      cursor: pointer;
      position: absolute;
      right: 44px;
      top: 50%;
      transform: translateY(-50%);
      height: 44px;
      line-height: 44px;
      width: 40px;
      text-align: center;
    }
  }

  &.error-field {
    input[type='text'], input[type='email'], input[type='password'] {
      border-color: $color-warning;
      caret-color: $color-warning;
    }

    .error-text {
      font-size: 11px;
      font-style: italic;
      color: $color-warning;
      font-weight: 700;
      position: absolute;
      top: calc(100% + 4px);
    }
  }
  .css-yk16xz-control {
    min-height: 44px;
    line-height: 44px;
  }
  .css-1hwfws3 {
    padding: 0 8px;
  }
  .css-w8afj7-Input {
    margin: 0;
    padding: 0;
  }
  .inner__control {
    border-color: $color-border;
    box-shadow: none;

    &:hover {
      border-color: $color-border;
    }
  }
  .inner__control--is-focused {
    border-color: $color-text;
    &:hover {
      border-color: $color-text;
    }
  }
}

.select-style {
  .inner__control {
    background-color: transparent;
    min-height: 46px;
    border-color: rgba($color-white, .4);

    &:hover {
      border-color: rgba($color-white, .7);
    }
  }

  .css-1uccc91-singleValue {
    color: $color-white;
  }

  .inner__value-container {
    padding: 9px 8px;
  }

  .inner__placeholder {
    color: $color-white;
  }

  .inner__input {
    input {
      color: $color-white !important;
    }
  }
  .inner__indicator-separator {
    display: none;
  }
  .inner__indicator {
    color: rgba($color-white, .4);

    &:hover {
      color: rgba($color-white, .7);
    }
  }

  .inner__menu {
    margin-bottom: 100px;

    .inner__menu-list {
      padding-top: 15px;
      padding-bottom: 15px;
    }
    .inner__option {
      color: $color-text;
      font-weight: 700;
      padding: 8px 40px;
      font-size: $normal;

      &:active {
        background-color: $color-primary;
      }
    }
    .inner__option inner__option--is-focused,
    .inner__option--is-selected,
    .css-9gakcf-option {
      background-color: $color-primary;
      color: $color-white;
    }
  }

}


