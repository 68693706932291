@media (max-width: $screen-sm) {
  body {
    font-size: round($base-font-size / 1.1);
  }
}

@media (max-width: $screen-sm) {
  .container-sm {
    max-width: 100%;
    min-height: calc(100vh - 70px);
  }
}