.sidebar {
  position: fixed;
  width: 210px;
  top: 0;
  left: 0;
  bottom: 0;
  z-index: 2;
  border-right: 1px solid $color-border;
  box-shadow: 0 3px 3px 0 rgba(0, 0, 0, 0.08);
  background-color: #fff;

  .logout {
    display: none;
  }


}

.sidebar-logo {
  height: 70px;
  border-bottom: 1px solid $color-border;
  justify-content: center;
  display: flex;
  align-items: center;
}

.sidebar-content {
  padding-top: 40px;
  height: calc(100vh - 70px);
  overflow: auto;

  .menu {
    li {
      position: relative;
      a {
        display: block;
        padding: 12px 10px 12px 65px;
        font-weight: 700;
        opacity: .7;
        transition: all .3s;
        font-size: $normal;

        &:hover {
          opacity: 1;
        }

        &.active {
          opacity: 1;
          color: $color-primary;
          position: relative;

          .icon {
            >span {
              &:before {
                color: $color-primary;
              }
            }
          }

          &:before {
            content: "";
            position: absolute;
            height: 100%;
            width: 5px;
            left: 0;
            top: 0;
            background-color: $color-primary;
            border-radius: 40px;
          }
        }
      }
    }

    .icon {
      position: absolute;
      left: 25px;
      top: 50%;
      transform: translateY(-50%);
    }
  }
}
.sidebar-left-btn {
  position: absolute;
  right: -40px;
  top: 20px;
  display: none;
  opacity: 0;
}

.sidebar-right {
  width: 540px;
  position: fixed;
  z-index: 4;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: #fff;
  border-left: 1px solid $color-border;
  padding:  35px 60px;
  transform: translateX(540px);
  transition: all .3s;
  overflow-y: auto;

  &.show {
    transform: translateX(0);
  }

  .sidebar-img {
    max-height: 180px;
    max-width: 100%;
  }
}
.sidebar-right-inner {
  min-height: 100%;
  padding-bottom: 80px;
  position: relative;
}

.sidebar-right-btn {
  display: none;
  position: absolute;
  top: -27px;
  right: -27px;
}

.sidebar-meta {
  display: flex;
  justify-content: flex-end;
  position: absolute;
  bottom: 0;
  right: 0;
  left: 0;
}