.switch {
  position: relative;
  display: inline-block;
  width: 34px;
  height: 14px;

  input {
    opacity: 0;
    width: 0;
    height: 0;
  }
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  //background-color: rgba($color-dark-grey);
  background-color: rgba($color-dark-grey, .45);
  border-radius: 34px;
  transition: .4s;


  &:before {
    position: absolute;
    content: "";
    height: 20px;
    width: 20px;
    left: -1px;
    top: -3px;
    background-color: $color-white;
    transition: .4s;
    border-radius: 50%;
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.25);
  }
}

input {
  &:checked + .slider {
    background-color: rgba($color-tertiary, .5);

    &:before {
      transform: translateX(19px);
      background-color: $color-tertiary;
    }
  }

  &:focus + .slider {
    box-shadow: 0 0 1px rgba($color-tertiary, .5);
  }

  &:disabled + .slider {
      opacity: .4;
  }
}