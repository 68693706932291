$resolutions: (
  xxs: $screen-xxs,
  xs: $screen-xs,
  sm: $screen-sm,
  md: $screen-md,
  lg: $screen-lg
) !default;

$resolutions-max: (
        xxs: $screen-xxs-max,
        xs: $screen-xs-max,
        sm: $screen-sm-max,
        md: $screen-md-max,
        lg: $screen-lg-max
) !default;

$grid-cols-count: 12;
$grid-spacing: 10px;