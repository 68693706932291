.tag-group {
  display: flex;
  flex-wrap: wrap;

  .tag-item {
    padding: 0 12px;
    line-height: 25px;
    height: 25px;
    background-color: $color-tertiary;
    margin-right: 8px;
    margin-bottom: 8px;
    border-radius: 3px;
    color: rgba($color-white, .9);
    font-size: $xsl;
    display: flex;
    align-items: center;

    .tag-text {
      @include ellipsis(101px);
    }

    &.with-close {
      padding: 0 6px 0 12px;
      .icon {
        margin-left: 7px;
        font-size: 18px;
        cursor: pointer;
        color: rgba($color-white, .6);
        transition: all .3s;

        &:hover {
          color: rgba($color-white, .8);
        }
      }
    }
  }
}
.tag-dropdown {
  position: relative;

  .dropdown-content {
    width: calc(100% - 43px);
    height: 225px;
    position: absolute;
    top: 100%;
    padding: 20px 20px 0;
    box-shadow: 0px 3px 6px rgba(0,0,0,.16);
    background-color: $color-bg;
    margin-bottom: 100px;
  }

  .tag-item {
    cursor: pointer;
    border: 1px solid $color-tertiary;
    transition: all .3s;

    &.selected {
      border: 1px solid $color-text;
      background-color: darken($color-tertiary, 3%);
    }
  }

}
