.tab-style {
  border: 1px solid $color-tertiary;
  border-radius: $br-default;
  display: flex;

  &.folder-mobile {

    li {

      .icon {
        display: none;
      }
    }
  }

  li {
    width: 34%;
    text-align: center;
    padding: 10px;
    font-family: $font-secondary;
    font-size: $normal;
    font-weight: 500;
    color: $color-text-50;
    transition: all .3s;
    cursor: pointer;

    &:not(:last-child) {
      border-right: 1px solid $color-tertiary;
    }

    &.active {
      color: $color-white;
      background-color: $color-tertiary;

    }
  }
}