header {
  text-align: right;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 2;
  justify-content: center;
  display: flex;
  align-items: center;
  height: 70px;
  padding-left: 210px;
  border-bottom: 1px solid $color-border;
  background-color: $color-bg;

  .right-side {
    .icon {
      color: $color-primary;
    }
  }
}

.sidebar-open-btn {
  position: absolute;
  top: 50%;
  left: 25px;
  transform: translateY(-50%);
}

.sidebar-open-btn {
  display: none;
}