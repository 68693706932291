@media (max-width: $screen-md-max) {
  .sign-form {
    padding: 20px 40px 30px;

    &.sign-up {
      max-width: 740px;
    }
  }
}

@media (max-width: $screen-sm-max) {
  .sign-form {
    padding: 20px;
    min-height: 100vh;

    &.sign-up, &.sign-in {
      max-width: 100%;
    }
  }
  .sign-form-inner {
    max-width: 440px;
    margin: 0 auto;
    padding-top: 30px;
  }
  .sign-body {
    padding-top: 20px;
  }
}