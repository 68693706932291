@media (max-width: $screen-sm-max) {
  .upload-img {
    padding: 60px 0;
  }
  .box {
    border: none;
  }

  .folder-group {
    grid-template-columns: repeat(2, calc(50% - 8px));;
  }

  .img-card-list {
    .img-card {
      width: 100%;
    }
  }
  .empty-page-3 {
    min-height: calc(100vh - 300px);
    height: auto;

    .pb-100 {
      padding-bottom: 0;
    }
  }

}

@media (max-width: $screen-xs-max) {
  .folder-group {
    grid-template-columns: repeat(1, 100%);
  }
}

@media (max-width: 359px) {
  .img-card-top {
    .img {
      width: 75px;
      height: 75px;
    }
  }
}