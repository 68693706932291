// Links
.link {
  color: $color-primary;
  text-decoration: none;
  font-size: $normal;


  &:not(.color-white):not(.color-warning) {
    &:hover, &:focus {
      color: darken($color-primary, 5%);
    }
  }

  &.with-icon {
    .icon {
      vertical-align: middle;
      margin-right: 7px;
    }
  }

}

.pointer {
  cursor: pointer;
}

.icon {
  font-size: 22px;

  span {
    display: inline-block;
  }

  &.blue, &.blue [class^="path"] {
    &:before {
      color: $color-primary;
    }
  }

  &.green, &.green [class^="path"] {
      &:before {
        color: $color-tertiary;
      }
  }

  &.red, &.red [class^="path"] {
    &:before {
      color: $color-warning;
    }
  }

  &.white, &.white [class^="path"] {
    &:before {
      color: $color-white;
    }
  }

  &.color-text {
    [class^="path"] {
      &:before {
        color: $color-text-70;
      }
    }
  }
}

.disabled {
  opacity: .6;
}

//borders

@each $key,$val in $position {
  .border-#{$key} {
    border-#{$val}: 1px solid $color-border;
  }
}


//floats
@each $key,$val in $resolutions {
  @media (min-width: $val) {
    // Alignment
    .float-#{$key}-left {
      float: left;
    }
    .float-#{$key}-right {
      float: right;
    }
  }
}

//fixes
.clear-after {
  @include clear-after;
}

.full-width {
  width: 100%;
}

.full-height {
  height: 100%;
}


//display
.flex {
  display: flex;
}
.inline-flex {
  display: inline-flex;
}
.wrap {
  flex-wrap: wrap;
}

.block {
  display: block;
}
.inline-block {
  display: inline-block;
}

/*alignments*/

.vertical-middle {
  vertical-align: middle;
}

//margins

.pull-right {
  margin-left: auto;
}
.pull-left {
  margin-right: auto;
}

/*ellipsis*/
.ellipsis {
  @include ellipsis(100%);
}

/*positions*/
.pos-rel {
  position: relative;
}
.pos-abs {
  position: absolute;
}

.justify {
  &-center { justify-content: center }
  &-start { justify-content: flex-start }
  &-end { justify-content: flex-end }
  &-around { justify-content: space-around }
  &-between { justify-content: space-between }
}
.content {
  &-center { align-content: center }
  &-start { align-content: flex-start }
  &-end { align-content: flex-end }
  &-around { align-content: space-around }
  &-between { align-content: space-between }
}
.align {
  &-center { align-items: center }
  &-start { align-items: flex-start }
  &-end { align-items: flex-end }
}
.flex-center-x {
  display: flex;
  justify-content: center;
}
.flex-center-y {
  display: flex;
  align-items: center;
}

.direction-column {
  flex-direction: column;
}

@each $key,$val in $resolutions-max {
  @media (max-width: $val) {
    .hide-#{$key} {
      display: none;
    }
  }
}