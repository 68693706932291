@media screen and (max-width: $screen-md-max) {
  .react-datepicker {
    padding: 0;
  }

  .react-datepicker__day-name,
  .react-datepicker__day,
  .react-datepicker__time-name {
    width: 30px;
    height: 30px;
    line-height: 30px;
    font-size: $xs;
  }

  .react-datepicker__current-month {
    padding-bottom: 10px;
  }

  .react-datepicker__navigation {
    top: 5px;
  }

  .react-datepicker__navigation {

    &.react-datepicker__navigation--previous {
      left: 30px;
    }

    &.react-datepicker__navigation--next {
      right: 30px;
    }
  }
}
@media (max-width: $screen-sm-max) {
  .align-right {
    .react-datepicker-popper {
      left: inherit !important;
      right: inherit !important;
    }
  }
}

@media (max-width: $screen-xs-max) {
  .Toastify__toast-container {
    padding: 0 10px;
  }
  .Toastify__toast {
    max-width: 100%;
  }
}

@media screen and (max-height: 670px) {
  .react-datepicker {
    padding: 0;
  }
  .react-datepicker__day-name,
  .react-datepicker__day,
  .react-datepicker__time-name {
    width: 25px;
    height: 25px;
    line-height: 25px;
    font-size: $xs;
  }

  .react-datepicker__current-month {
    padding-bottom: 5px;
  }

  .react-datepicker__navigation {
    top: 5px;
  }

  .react-datepicker__navigation {

    &.react-datepicker__navigation--previous {
      left: 30px;
    }

    &.react-datepicker__navigation--next {
      right: 30px;
    }
  }
}
