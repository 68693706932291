/*
 * Buttons global styles.
 */
button {
  -webkit-tap-highlight-color: transparent;
}

.no-style {
  border: 0;
  padding: 0;
  background-color: transparent;
  outline: none;
  cursor: pointer;

  &:focus {
    outline: none;
  }
}

.btn {
  font-size: $normal;
  height: 46px;
  cursor: pointer;
  font-weight: 500;
  text-align: center;
  white-space: nowrap;
  display: inline-block;
  border-radius: $br-default;
  outline: none;
  font-family: $font-secondary;
  transition: all .3s;
  padding: 0 15px;
  min-width: 110px;
  max-width: 350px;

  &.ripple {
    overflow: hidden;

    span {
      display: inline-block;
      position: relative;
      z-index: 1;

      &:after {
        content: '';
        position: absolute;
        z-index: -1;
        top: 50%;
        left: 50%;
        background: rgba(255,255,255, 0.5);
        width: 80px;
        height: 80px;
        border-radius: 50%;
        transform: translateX(-50%) translateY(-50%) scale(0);
      }
    }
    //button:focus:not(:active) span::after
    &:focus {
      &:not(:active) {
        span {
          &:after {
            animation: circleGrow 0.35s linear;
          }
        }
      }
    }
  }

  /* Filled buttons */
  &.filled {
    border: 0;
    color: #fff;
    line-height: 45px;

    @each $key,$val in $main-colors {
      &.#{$key} {
        background-color: #{$val};

        &:hover {
          background-color: darken($val, 4%);
        }
      }
    }

    &.color-primary {
      color: $color-primary;
    }

    &.color-text {
      color: $color-text;
    }
  }

  /* Outline buttons */
  &.outline {
    line-height: 46px;
    border: 1px solid transparent;
    box-shadow: 1px 1px 3px 0 rgba(0, 0, 0, 0.08);
    color: $color-primary;

    &.with-icon {
      .icon {
        vertical-align: middle;
        margin-right: 7px;
      }
    }

    @each $key,$val in $main-colors {
      &.#{$key} {
        border-color: rgba($val, .2);
        background-color: rgba( $val, .05 );
        color: $val;

        &:hover {
          border-color: darken($val, 8%);
        }
      }
    }
  }

  /* Link buttons */
  &.link-btn {
    border: 0;
    line-height: 41px;
    background-color: transparent;

    &.with-icon {
      .icon {
        vertical-align: middle;
        margin-right: 7px;
      }
    }

    @each $key,$val in $main-colors {
      &.#{$key} {
        color: #{$val};

        &:hover {
          color: darken($val, 4%);
        }
      }
    }
  }

  &:hover {
    text-decoration: none;

    &:not(.link):not([disabled]):not(.disabled):not(.link-btn) {
      box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
    }
  }

  &[disabled], &.disabled {
    border: 0;
    cursor: default;
    line-height: 41px;
    color: rgba(#fff, 0.6);
    opacity: .7;
  }

  &.small {
    height: 35px;
    font-size: $sm;
    line-height: 35px;
  }

  /* Buttons size modifications */
  &.min-110 {
    min-width: 110px;
  }
  &.min-160 {
    min-width: 160px;
  }
  &.min-180 {
    min-width: 180px;
  }
  &.min-220 {
    min-width: 220px;
  }
}

.drawer-btn {
  font-size: 28px;
  cursor: pointer;
  
  .icon-close {
    display: block;
  }
}

.btn-360 {
  border: 1px solid rgba(0,0,0,0.4);
  padding: 0 7px;
  border-radius: $br-default;
  background-color: $color-white;
  color: #000;
  height: 28px;
  line-height: 28px;
  font-size: 13px;
  position: absolute;
  right: 5px;
  bottom: 5px;
  z-index: 1;
}

