$icomoon-font-family: "icomoon" !default;
$icomoon-font-path: "../fonts/icomoon" !default;

$icon-default: "\e944";
$icon-group1-path1: "\e947";
$icon-group1-path2: "\e948";
$icon-group1-path3: "\e949";
$icon-deactivate-path1: "\e913";
$icon-deactivate-path2: "\e914";
$icon-tag-path1: "\e915";
$icon-tag-path2: "\e942";
$icon-tag-path3: "\e943";
$icon-download-path1: "\e945";
$icon-download-path2: "\e946";
$icon-done: "\e939";
$icon-resend: "\e93a";
$icon-active-path1: "\e93c";
$icon-active-path2: "\e93d";
$icon-active-path3: "\e93e";
$icon-active-path4: "\e93f";
$icon-active-path5: "\e940";
$icon-active-path6: "\e941";
$icon-warning-path1: "\e931";
$icon-warning-path2: "\e932";
$icon-error-path1: "\e933";
$icon-error-path2: "\e934";
$icon-info-path1: "\e935";
$icon-info-path2: "\e936";
$icon-success-path1: "\e937";
$icon-success-path2: "\e938";
$icon-restore: "\e92c";
$icon-dots: "\e92b";
$icon-business-path1: "\e925";
$icon-business-path2: "\e926";
$icon-date-range-path1: "\e927";
$icon-date-range-path2: "\e928";
$icon-folder-open-path1: "\e929";
$icon-folder-open-path2: "\e92a";
$icon-account-circle-path1: "\e92d";
$icon-account-circle-path2: "\e92e";
$icon-arrow-back: "\e92f";
$icon-arrow-dropdown: "\e930";
$icon-360: "\e924";
$icon-menu: "\e923";
$icon-time-path1: "\e91a";
$icon-time-path2: "\e91e";
$icon-cloud-path1: "\e91c";
$icon-cloud-path2: "\e91d";
$icon-next: "\e91f";
$icon-save-path1: "\e920";
$icon-save-path2: "\e921";
$icon-prev: "\e922";
$icon-checked: "\e93b";
$icon-close: "\e91b";
$icon-admin-path1: "\e900";
$icon-admin-path2: "\e901";
$icon-delete-path1: "\e902";
$icon-delete-path2: "\e903";
$icon-edit-path1: "\e904";
$icon-edit-path2: "\e905";
$icon-email-path1: "\e906";
$icon-email-path2: "\e907";
$icon-lock-path1: "\e908";
$icon-lock-path2: "\e909";
$icon-photo-path1: "\e90a";
$icon-photo-path2: "\e90b";
$icon-search: "\e90c";
$icon-settings-path1: "\e90d";
$icon-settings-path2: "\e90e";
$icon-turn-off: "\e90f";
$icon-user-path1: "\e910";
$icon-user-path2: "\e911";
$icon-user-path3: "\e912";
$icon-worker-path1: "\e916";
$icon-worker-path2: "\e917";
$icon-worker-path3: "\e918";
$icon-add: "\e919";