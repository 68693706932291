//paths
$fonts-folder: "../fonts";
$images-folder: '../img/';

//fonts
$font-primary: 'Lato', sans-serif;
$font-secondary: 'Montserrat', sans-serif;
$font-icons: "Icomoon Project Name";

//color usage
$color-primary: #179DFF;
$color-secondary: #1e3143;
$color-tertiary: #26BE7C;
$color-green-dark: #209261;
$color-bg: #F8F8F8;
$color-warning: #ca3838;
$color-orange: #EF9134;
$color-border: rgba(12,12,12,.1);
$color-white: #fff;
$color-grey: #eee;
$color-dark-grey: #221F1F;

//Descriptive Base Colors
$color-text: rgb(12, 12, 12);
$color-text-70: rgba(12, 12, 12, 0.7);
$color-text-50: rgba(12, 12, 12, 0.5);
$color-text-10: rgba(12, 12, 12, 0.1);

//Text
$base-font-size: 14px;

$xs: 12px; $xsl: 13px;  $sm: 14px; $normal: 16px;  $md: 18px;  $lg: 24px;  $xl: 32px;

$text-font-sizes: (
        xs: $xs, xsl: $xsl, sm: $sm, normal: $normal, md: $md, lg: $lg, xl: $xl
) !default;

$heading-font-sizes: (
        h6: $xs, h5: $sm, h4: $normal, h3: $md, h2: $lg, h1: $xl
) !default;

$main-colors: (
        primary: $color-primary, secondary: $color-secondary, warning: $color-warning, text: $color-text, text-70: $color-text-70, text-50: $color-text-50, white: $color-white
) !default;

$position: (
  top: top, bottom: bottom, left: left, right: right
)!default;

//Border Radiuses
$br-default: 4px;
$br-md: 10px;
$br-lg: 30px;
$br-rounded: 50%;
$br-none: 0;

// Transitions
$trns-duration: .3s;
$trns-duration-lg: 1s;
$trns-delay: 50ms;

//container
$cont-width: 840px;
$cont-width-sm: 550px;
$cont-padding: 30px;

//Resolutions-max
$screen-lg-max: 1199px;
$screen-md-max: 1023px;
$screen-sm-max: 767px;
$screen-xs-max: 499px;
$screen-xxs-max: 359px;


$screen-lg: 1200px;
$screen-md: 1024px;
$screen-sm: 768px;
$screen-xs: 500px;
$screen-xxs: 360px;