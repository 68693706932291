/*datepicker*/
.react-datepicker {
  border: 1px solid $color-border;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.07);
  font-family: $font-primary;
  padding: 20px 50px;
}

.react-datepicker-wrapper,
.react-datepicker__input-container,
.react-datepicker-ignore-onclickoutside {
  width: 100%;
}

.align-right {
  .react-datepicker-popper {
    left: inherit !important;
    right: 0;
  }
}

.react-datepicker__triangle {
  display: none;
}

.react-datepicker__header {
  background-color: $color-white;
  border-bottom: none;
}
.react-datepicker__day-name {
  color: $color-text-50;
}
.react-datepicker__current-month {
  padding-bottom: 25px;
}
.react-datepicker__day-name,
.react-datepicker__day,
.react-datepicker__time-name {
  width: 36px;
  height: 36px;
  line-height: 36px;
  font-size: $xs;
}
.react-datepicker__day--selected,
.react-datepicker__day--in-selecting-range,
.react-datepicker__day--in-range,
.react-datepicker__month-text--selected,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__month-text--in-range {
  background-color: $color-primary;
  border-radius: 50%;
  transition: background-color .2s;

  &:hover {
    background-color: darken($color-primary, 10%);
  }
}

.react-datepicker__day,
.react-datepicker__month-text {
  &:hover {
    border-radius: 50%;
  }
}

.react-datepicker__day--keyboard-selected,
.react-datepicker__month-text--keyboard-selected {
  background-color: $color-primary;
  border-radius: 50%;
  background-color: darken($color-primary, 10%);
}

.react-datepicker__navigation {
  border: none;
  overflow: visible;
  top: 25px;

  &:after {
    font-family: $icomoon-font-family;
    position: absolute;
    top: 0;
    width: 24px;
    height: 24px;
    font-size: 24px;
    color: $color-primary;
    text-indent: 0;
  }

  &.react-datepicker__navigation--previous {
    left: 65px;

    &:after {
      content: "\e922";
      left: 0;
    }
  }

  &.react-datepicker__navigation--next {
    right: 65px;

    &:after {
      content: "\e91f";
      right: 0;
    }
  }
}

/*pagination*/
.rc-pagination {
  >li {
    border-radius: 4px;
    color: $color-primary;
  }
}
.rc-pagination-prev {
  a {
    &:after {
      content: "\e922";
      font-family: $icomoon-font-family;
    }
  }
}
.rc-pagination-next {
  a {
    &:after {
      content: "\e91f";
      font-family: $icomoon-font-family;
    }
  }
}

.my-collapsible__content {
  overflow: hidden;
}

/*toast*/
.Toastify__toast {
  padding: 0;
  border-radius: $br-default;
  min-height: 59px;
  max-width: 410px;
  box-shadow: 7px 7px 15px rgba(0,0,0,.15);
  margin: 10px 0;
}
.Toastify__toast-body {
  margin: 0;
}
.Toastify__progress-bar {
  opacity: 0;
  visibility: hidden;
}
.Toastify__close-button--default {
  padding: 7px 7px 0 0;
}

.toast-style {
  display: flex;
  height: 100%;

  &.error {
    .left-side {
      background-color: $color-warning;
    }
  }
  &.success {
    .left-side {
      background-color: $color-tertiary;
    }
  }
  &.info {
    .left-side {
      background-color: $color-primary;
    }
  }
  &.warning {
    .left-side {
      background-color: $color-orange;
    }
  }

  .left-side {
    width: 60px;
    display: flex;
    align-items: center;
    justify-content: center;

    .icon {
      .path1 {
        &:before {
          color: rgb(255, 255, 255);
        }
      }
      .path2 {
        &:before {
          color: rgb(255, 255, 255);
        }
      }
    }
  }
  .right-side {
    width: calc(100% - 60px);
    padding: 20px;
  }
  p {
    font-size: $normal;
    color: $color-text-70;
  }
}

.__react_component_tooltip {
  border-radius: 2px;
  font-weight: 500;
  width: auto;

  &.type-dark {
    background-color: #616161;
  }

  &:after {
    content: none;
  }
}
