.dropdown {
  box-shadow: 0 3px 6px rgba(0,0,0,.15);

  .dropdown-btn {
    padding: 15px 40px 15px 20px;
    position: relative;
    cursor: pointer;
  }

  .dropdown-arrow {
    position: absolute;
    top: 50%;
    right: 25px;
    transform: translateY(-50%) rotate(90deg);
    transition: all .3s;
  }

  .dropdown-content {
    padding: 20px;
    display: none;
    visibility: hidden;
    opacity: 0;
    transition: all .3s;
    border-top: 1px solid $color-border;
  }

  &.open {
    .dropdown-content {
      display: block;
      visibility: visible;
      opacity: 1;
    }

    .dropdown-arrow {
      transform: translateY(-50%) rotate(-90deg);
    }
  }


}