@keyframes circleGrow {
  0% {
    transform: translateX(-50%) translateY(-50%) scale(0);
    opacity: 0;
  }
  20% {
    opacity: 1;
  }
  100% {
    transform: translateX(-50%) translateY(-50%) scale(6);
    opacity: 0;
  }
}