@import "variables";

@font-face {
  font-family: '#{$icomoon-font-family}';
  src:  url('#{$icomoon-font-path}/#{$icomoon-font-family}.eot?rsaqvv');
  src:  url('#{$icomoon-font-path}/#{$icomoon-font-family}.eot?rsaqvv#iefix') format('embedded-opentype'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.ttf?rsaqvv') format('truetype'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.woff?rsaqvv') format('woff'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.svg?rsaqvv##{$icomoon-font-family}') format('svg');
  font-weight: normal;
  font-style: normal;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: '#{$icomoon-font-family}' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-default {
  &:before {
    content: $icon-default;
  }
}
.icon-group1 .path1 {
  &:before {
    content: $icon-group1-path1;
    color: rgb(0, 0, 0);
    opacity: 0.3;
  }
}
.icon-group1 .path2 {
  &:before {
    content: $icon-group1-path2;
    margin-left: -1em;
    color: rgb(0, 0, 0);
    opacity: 0.3;
  }
}
.icon-group1 .path3 {
  &:before {
    content: $icon-group1-path3;
    margin-left: -1em;
    color: rgb(0, 0, 0);
  }
}
.icon-deactivate .path1 {
  &:before {
    content: $icon-deactivate-path1;
    color: rgb(0, 0, 0);
  }
}
.icon-deactivate .path2 {
  &:before {
    content: $icon-deactivate-path2;
    margin-left: -1em;
    color: rgb(0, 0, 0);
    opacity: 0.3;
  }
}
.icon-tag .path1 {
  &:before {
    content: $icon-tag-path1;
    color: rgb(0, 0, 0);
    opacity: 0.3;
  }
}
.icon-tag .path2 {
  &:before {
    content: $icon-tag-path2;
    margin-left: -1em;
    color: rgb(0, 0, 0);
  }
}
.icon-tag .path3 {
  &:before {
    content: $icon-tag-path3;
    margin-left: -1em;
    color: rgb(0, 0, 0);
  }
}
.icon-download .path1 {
  &:before {
    content: $icon-download-path1;
    color: rgb(0, 0, 0);
  }
}
.icon-download .path2 {
  &:before {
    content: $icon-download-path2;
    margin-left: -1em;
    color: rgb(0, 0, 0);
    opacity: 0.3;
  }
}
.icon-done {
  &:before {
    content: $icon-done;
  }
}
.icon-resend {
  &:before {
    content: $icon-resend;
  }
}
.icon-active .path1 {
  &:before {
    content: $icon-active-path1;
    color: rgb(0, 0, 0);
    opacity: 0.3;
  }
}
.icon-active .path2 {
  &:before {
    content: $icon-active-path2;
    margin-left: -1em;
    color: rgb(0, 0, 0);
    opacity: 0.3;
  }
}
.icon-active .path3 {
  &:before {
    content: $icon-active-path3;
    margin-left: -1em;
    color: rgb(0, 0, 0);
  }
}
.icon-active .path4 {
  &:before {
    content: $icon-active-path4;
    margin-left: -1em;
    color: rgb(0, 0, 0);
  }
}
.icon-active .path5 {
  &:before {
    content: $icon-active-path5;
    margin-left: -1em;
    color: rgb(0, 0, 0);
    opacity: 0.3;
  }
}
.icon-active .path6 {
  &:before {
    content: $icon-active-path6;
    margin-left: -1em;
    color: rgb(0, 0, 0);
  }
}
.icon-warning .path1 {
  &:before {
    content: $icon-warning-path1;
    color: rgb(0, 0, 0);
    opacity: 0.3;
  }
}
.icon-warning .path2 {
  &:before {
    content: $icon-warning-path2;
    margin-left: -1em;
    color: rgb(0, 0, 0);
  }
}
.icon-error .path1 {
  &:before {
    content: $icon-error-path1;
    color: rgb(0, 0, 0);
    opacity: 0.3;
  }
}
.icon-error .path2 {
  &:before {
    content: $icon-error-path2;
    margin-left: -1em;
    color: rgb(0, 0, 0);
  }
}
.icon-info .path1 {
  &:before {
    content: $icon-info-path1;
    color: rgb(0, 0, 0);
    opacity: 0.3;
  }
}
.icon-info .path2 {
  &:before {
    content: $icon-info-path2;
    margin-left: -1em;
    color: rgb(0, 0, 0);
  }
}
.icon-success .path1 {
  &:before {
    content: $icon-success-path1;
    color: rgb(0, 0, 0);
    opacity: 0.3;
  }
}
.icon-success .path2 {
  &:before {
    content: $icon-success-path2;
    margin-left: -1em;
    color: rgb(0, 0, 0);
  }
}
.icon-restore {
  &:before {
    content: $icon-restore;
  }
}
.icon-dots {
  &:before {
    content: $icon-dots;
  }
}
.icon-business .path1 {
  &:before {
    content: $icon-business-path1;
    color: rgb(0, 0, 0);
    opacity: 0.3;
  }
}
.icon-business .path2 {
  &:before {
    content: $icon-business-path2;
    margin-left: -1em;
    color: rgb(0, 0, 0);
  }
}
.icon-date-range .path1 {
  &:before {
    content: $icon-date-range-path1;
    color: rgb(0, 0, 0);
    opacity: 0.3;
  }
}
.icon-date-range .path2 {
  &:before {
    content: $icon-date-range-path2;
    margin-left: -1em;
    color: rgb(0, 0, 0);
  }
}
.icon-folder-open .path1 {
  &:before {
    content: $icon-folder-open-path1;
    color: rgb(0, 0, 0);
    opacity: 0.3;
  }
}
.icon-folder-open .path2 {
  &:before {
    content: $icon-folder-open-path2;
    margin-left: -1em;
    color: rgb(0, 0, 0);
  }
}
.icon-account-circle .path1 {
  &:before {
    content: $icon-account-circle-path1;
    color: rgb(0, 0, 0);
    opacity: 0.3;
  }
}
.icon-account-circle .path2 {
  &:before {
    content: $icon-account-circle-path2;
    margin-left: -1em;
    color: rgb(0, 0, 0);
  }
}
.icon-arrow-back {
  &:before {
    content: $icon-arrow-back;
  }
}
.icon-arrow-dropdown {
  &:before {
    content: $icon-arrow-dropdown;
  }
}
.icon-360 {
  &:before {
    content: $icon-360;
  }
}
.icon-menu {
  &:before {
    content: $icon-menu;
  }
}
.icon-time .path1 {
  &:before {
    content: $icon-time-path1;
    color: rgb(12, 12, 12);
    opacity: 0.3;
  }
}
.icon-time .path2 {
  &:before {
    content: $icon-time-path2;
    margin-left: -1em;
    color: rgb(12, 12, 12);
  }
}
.icon-cloud .path1 {
  &:before {
    content: $icon-cloud-path1;
    color: rgb(0, 0, 0);
    opacity: 0.3;
  }
}
.icon-cloud .path2 {
  &:before {
    content: $icon-cloud-path2;
    margin-left: -1em;
    color: rgb(0, 0, 0);
  }
}
.icon-next {
  &:before {
    content: $icon-next;
  }
}
.icon-save .path1 {
  &:before {
    content: $icon-save-path1;
    color: rgb(0, 0, 0);
    opacity: 0.3;
  }
}
.icon-save .path2 {
  &:before {
    content: $icon-save-path2;
    margin-left: -1em;
    color: rgb(0, 0, 0);
  }
}
.icon-prev {
  &:before {
    content: $icon-prev;
  }
}
.icon-checked {
  &:before {
    content: $icon-checked;
  }
}
.icon-close {
  &:before {
    content: $icon-close;
  }
}
.icon-admin .path1 {
  &:before {
    content: $icon-admin-path1;
    color: rgb(0, 0, 0);
  }
}
.icon-admin .path2 {
  &:before {
    content: $icon-admin-path2;
    margin-left: -1em;
    color: rgb(0, 0, 0);
    opacity: 0.3;
  }
}
.icon-delete .path1 {
  &:before {
    content: $icon-delete-path1;
    color: rgb(0, 0, 0);
    opacity: 0.3;
  }
}
.icon-delete .path2 {
  &:before {
    content: $icon-delete-path2;
    margin-left: -1em;
    color: rgb(0, 0, 0);
  }
}
.icon-edit .path1 {
  &:before {
    content: $icon-edit-path1;
    color: rgb(0, 0, 0);
    opacity: 0.3;
  }
}
.icon-edit .path2 {
  &:before {
    content: $icon-edit-path2;
    margin-left: -1em;
    color: rgb(0, 0, 0);
  }
}
.icon-email .path1 {
  &:before {
    content: $icon-email-path1;
    color: rgb(0, 0, 0);
    opacity: 0.3;
  }
}
.icon-email .path2 {
  &:before {
    content: $icon-email-path2;
    margin-left: -1em;
    color: rgb(0, 0, 0);
  }
}
.icon-lock .path1 {
  &:before {
    content: $icon-lock-path1;
    color: rgb(12, 12, 12);
    opacity: 0.3;
  }
}
.icon-lock .path2 {
  &:before {
    content: $icon-lock-path2;
    margin-left: -1em;
    color: rgb(12, 12, 12);
  }
}
.icon-photo .path1 {
  &:before {
    content: $icon-photo-path1;
    color: rgb(0, 0, 0);
    opacity: 0.3;
  }
}
.icon-photo .path2 {
  &:before {
    content: $icon-photo-path2;
    margin-left: -1em;
    color: rgb(0, 0, 0);
  }
}
.icon-search {
  &:before {
    content: $icon-search;
  }
}
.icon-settings .path1 {
  &:before {
    content: $icon-settings-path1;
    color: rgb(0, 0, 0);
    opacity: 0.3;
  }
}
.icon-settings .path2 {
  &:before {
    content: $icon-settings-path2;
    margin-left: -1em;
    color: rgb(0, 0, 0);
  }
}
.icon-turn-off {
  &:before {
    content: $icon-turn-off;
  }
}
.icon-user .path1 {
  &:before {
    content: $icon-user-path1;
    color: rgb(12, 12, 12);
    opacity: 0.3;
  }
}
.icon-user .path2 {
  &:before {
    content: $icon-user-path2;
    margin-left: -1em;
    color: rgb(12, 12, 12);
    opacity: 0.3;
  }
}
.icon-user .path3 {
  &:before {
    content: $icon-user-path3;
    margin-left: -1em;
    color: rgb(12, 12, 12);
  }
}
.icon-worker .path1 {
  &:before {
    content: $icon-worker-path1;
    color: rgb(0, 0, 0);
  }
}
.icon-worker .path2 {
  &:before {
    content: $icon-worker-path2;
    margin-left: -1em;
    color: rgb(0, 0, 0);
    opacity: 0.3;
  }
}
.icon-worker .path3 {
  &:before {
    content: $icon-worker-path3;
    margin-left: -1em;
    color: rgb(0, 0, 0);
  }
}
.icon-add {
  &:before {
    content: $icon-add;
  }
}