

@each $key,$val in $text-font-sizes {
  .font-#{$key} {
    font-size: #{$val};
  }
}

/* Heading Text Sizes */

@each $key,$val in $heading-font-sizes {
  #{$key}, .#{$key} {
    font-size: #{$val};
  }
}

/* Text Colors */

@each $key,$val in $main-colors {
  .color-#{$key} {
    color: #{$val};
  }
}

.lh-24 {
  line-height: 24px;
}

.italic {
  font-style: italic;
}

.fw-400 {
  font-weight: 400;
}
.fw-500 {
  font-weight: 500;
}
.fw-600 {
  font-weight: 600;
}
.fw-700 {
  font-weight: 700;
}
