@media (max-width: $screen-sm-max) {
  .stepper {
    .stepper-header {
      padding: 30px 30px 20px;
    }

    .stepper-header-top {
      display: none;
    }
    .stepper-header-bottom {
      padding-top: 0;
    }
  }
}