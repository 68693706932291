@media (max-width: $screen-lg-max) {
  .bar-bottom {
    left: 170px;
  }
}

@media (max-width: $screen-md-max) {
  .bar-bottom {
    left: 0;
  }
}

@media (max-width: $screen-sm-max) {
  .image-list {
    grid-gap: 10px;

    &:not(.with-right-btn) {
      grid-template-columns: repeat(4, 1fr);
      grid-auto-rows: 130px;
    }

    &.with-right-btn {
      grid-template-columns: repeat(4, 1fr);
      grid-auto-rows: 100px;
    }
  }

  .bar-bottom {

    >.flex {
      justify-content: space-between;
    }
  }
}

@media (max-width: $screen-xs-max) {
  .image-list {

    &:not(.with-right-btn) {
      grid-template-columns: repeat(3, 1fr);
      grid-auto-rows: 90px;
    }

    &.with-right-btn {
      grid-template-columns: repeat(3, 1fr);
      grid-auto-rows: 80px;
    }

  }
}

@media (max-width: $screen-xxs-max) {
  .image-list {

    &.with-right-btn {
      grid-template-columns: repeat(2, 1fr);
      grid-auto-rows: 80px;
    }

  }
}