html {
  position: relative;
  min-height: 100%;
  box-sizing: border-box;
}

body {
  color: $color-text;
  font-size: $base-font-size;
  font-family: $font-primary;
  outline: none;
  word-wrap: break-word;
  font-weight: 400;
}

*, *:before, *:after {
  outline: none;
  box-sizing: inherit;
}

.container {
  max-width: ($cont-width + $cont-padding);
  width: 100%;
  padding-left: ($cont-padding / 2);
  padding-right: ($cont-padding / 2);
  margin-left: auto;
  margin-right: auto;
}

.container-sm {
  max-width: ($cont-width-sm + $cont-padding);
  width: 100%;
  padding-left: ($cont-padding / 2);
  padding-right: ($cont-padding / 2);
  margin-left: auto;
  margin-right: auto;
}