@import url(https://fonts.googleapis.com/css?family=Lato:400,400i,700,700i,900,900i|Montserrat:400,400i,500,500i,600,600i,700,700i,800&display=swap);
@charset "UTF-8";
@font-face {
  font-family: "Icomoon Project Name";
  font-style: normal;
  font-weight: 400;
  src: url(/static/media/icomoon.a9f1834d.eot);
  src: url(/static/media/icomoon.a9f1834d.eot?#iefix) format("embedded-opentype"), url(/static/media/icomoon.e3ae8248.woff) format("woff"), url(/static/media/icomoon.01faec55.ttf) format("truetype"), url(/static/media/icomoon.0e483a85.svg) format("svg"); }

html, body, div, span, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
abbr, address, cite, code,
del, dfn, em, img, ins, kbd, q, samp,
small, strong, sub, sup, var,
b, i,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, figcaption, figure,
footer, header, hgroup, menu, nav, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent; }

article, aside, details, figcaption, figure, dialog,
footer, header, hgroup, menu, nav, section, main {
  display: block; }

ul, li {
  list-style: none; }

blockquote, q {
  quotes: none; }
  blockquote:before, blockquote:after, q:before, q:after {
    content: '';
    content: none; }

a {
  text-decoration: none;
  font-size: 100%;
  color: inherit; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

html {
  position: relative;
  min-height: 100%;
  box-sizing: border-box; }

body {
  color: #0c0c0c;
  font-size: 14px;
  font-family: "Lato", sans-serif;
  outline: none;
  word-wrap: break-word;
  font-weight: 400; }

*, *:before, *:after {
  outline: none;
  box-sizing: inherit; }

.container {
  max-width: 870px;
  width: 100%;
  padding-left: 15px;
  padding-right: 15px;
  margin-left: auto;
  margin-right: auto; }

.container-sm {
  max-width: 580px;
  width: 100%;
  padding-left: 15px;
  padding-right: 15px;
  margin-left: auto;
  margin-right: auto; }

@font-face {
  font-family: "icomoon";
  src: url(/static/media/icomoon.a9f1834d.eot);
  src: url(/static/media/icomoon.a9f1834d.eot#iefix) format("embedded-opentype"), url(/static/media/icomoon.01faec55.ttf) format("truetype"), url(/static/media/icomoon.e3ae8248.woff) format("woff"), url(/static/media/icomoon.0e483a85.svg#icomoon) format("svg");
  font-weight: normal;
  font-style: normal; }

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "icomoon" !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  -webkit-font-feature-settings: normal;
          font-feature-settings: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.icon-default:before {
  content: ""; }

.icon-group1 .path1:before {
  content: "";
  color: black;
  opacity: 0.3; }

.icon-group1 .path2:before {
  content: "";
  margin-left: -1em;
  color: black;
  opacity: 0.3; }

.icon-group1 .path3:before {
  content: "";
  margin-left: -1em;
  color: black; }

.icon-deactivate .path1:before {
  content: "";
  color: black; }

.icon-deactivate .path2:before {
  content: "";
  margin-left: -1em;
  color: black;
  opacity: 0.3; }

.icon-tag .path1:before {
  content: "";
  color: black;
  opacity: 0.3; }

.icon-tag .path2:before {
  content: "";
  margin-left: -1em;
  color: black; }

.icon-tag .path3:before {
  content: "";
  margin-left: -1em;
  color: black; }

.icon-download .path1:before {
  content: "";
  color: black; }

.icon-download .path2:before {
  content: "";
  margin-left: -1em;
  color: black;
  opacity: 0.3; }

.icon-done:before {
  content: ""; }

.icon-resend:before {
  content: ""; }

.icon-active .path1:before {
  content: "";
  color: black;
  opacity: 0.3; }

.icon-active .path2:before {
  content: "";
  margin-left: -1em;
  color: black;
  opacity: 0.3; }

.icon-active .path3:before {
  content: "";
  margin-left: -1em;
  color: black; }

.icon-active .path4:before {
  content: "";
  margin-left: -1em;
  color: black; }

.icon-active .path5:before {
  content: "";
  margin-left: -1em;
  color: black;
  opacity: 0.3; }

.icon-active .path6:before {
  content: "";
  margin-left: -1em;
  color: black; }

.icon-warning .path1:before {
  content: "";
  color: black;
  opacity: 0.3; }

.icon-warning .path2:before {
  content: "";
  margin-left: -1em;
  color: black; }

.icon-error .path1:before {
  content: "";
  color: black;
  opacity: 0.3; }

.icon-error .path2:before {
  content: "";
  margin-left: -1em;
  color: black; }

.icon-info .path1:before {
  content: "";
  color: black;
  opacity: 0.3; }

.icon-info .path2:before {
  content: "";
  margin-left: -1em;
  color: black; }

.icon-success .path1:before {
  content: "";
  color: black;
  opacity: 0.3; }

.icon-success .path2:before {
  content: "";
  margin-left: -1em;
  color: black; }

.icon-restore:before {
  content: ""; }

.icon-dots:before {
  content: ""; }

.icon-business .path1:before {
  content: "";
  color: black;
  opacity: 0.3; }

.icon-business .path2:before {
  content: "";
  margin-left: -1em;
  color: black; }

.icon-date-range .path1:before {
  content: "";
  color: black;
  opacity: 0.3; }

.icon-date-range .path2:before {
  content: "";
  margin-left: -1em;
  color: black; }

.icon-folder-open .path1:before {
  content: "";
  color: black;
  opacity: 0.3; }

.icon-folder-open .path2:before {
  content: "";
  margin-left: -1em;
  color: black; }

.icon-account-circle .path1:before {
  content: "";
  color: black;
  opacity: 0.3; }

.icon-account-circle .path2:before {
  content: "";
  margin-left: -1em;
  color: black; }

.icon-arrow-back:before {
  content: ""; }

.icon-arrow-dropdown:before {
  content: ""; }

.icon-360:before {
  content: ""; }

.icon-menu:before {
  content: ""; }

.icon-time .path1:before {
  content: "";
  color: #0c0c0c;
  opacity: 0.3; }

.icon-time .path2:before {
  content: "";
  margin-left: -1em;
  color: #0c0c0c; }

.icon-cloud .path1:before {
  content: "";
  color: black;
  opacity: 0.3; }

.icon-cloud .path2:before {
  content: "";
  margin-left: -1em;
  color: black; }

.icon-next:before {
  content: ""; }

.icon-save .path1:before {
  content: "";
  color: black;
  opacity: 0.3; }

.icon-save .path2:before {
  content: "";
  margin-left: -1em;
  color: black; }

.icon-prev:before {
  content: ""; }

.icon-checked:before {
  content: ""; }

.icon-close:before {
  content: ""; }

.icon-admin .path1:before {
  content: "";
  color: black; }

.icon-admin .path2:before {
  content: "";
  margin-left: -1em;
  color: black;
  opacity: 0.3; }

.icon-delete .path1:before {
  content: "";
  color: black;
  opacity: 0.3; }

.icon-delete .path2:before {
  content: "";
  margin-left: -1em;
  color: black; }

.icon-edit .path1:before {
  content: "";
  color: black;
  opacity: 0.3; }

.icon-edit .path2:before {
  content: "";
  margin-left: -1em;
  color: black; }

.icon-email .path1:before {
  content: "";
  color: black;
  opacity: 0.3; }

.icon-email .path2:before {
  content: "";
  margin-left: -1em;
  color: black; }

.icon-lock .path1:before {
  content: "";
  color: #0c0c0c;
  opacity: 0.3; }

.icon-lock .path2:before {
  content: "";
  margin-left: -1em;
  color: #0c0c0c; }

.icon-photo .path1:before {
  content: "";
  color: black;
  opacity: 0.3; }

.icon-photo .path2:before {
  content: "";
  margin-left: -1em;
  color: black; }

.icon-search:before {
  content: ""; }

.icon-settings .path1:before {
  content: "";
  color: black;
  opacity: 0.3; }

.icon-settings .path2:before {
  content: "";
  margin-left: -1em;
  color: black; }

.icon-turn-off:before {
  content: ""; }

.icon-user .path1:before {
  content: "";
  color: #0c0c0c;
  opacity: 0.3; }

.icon-user .path2:before {
  content: "";
  margin-left: -1em;
  color: #0c0c0c;
  opacity: 0.3; }

.icon-user .path3:before {
  content: "";
  margin-left: -1em;
  color: #0c0c0c; }

.icon-worker .path1:before {
  content: "";
  color: black; }

.icon-worker .path2:before {
  content: "";
  margin-left: -1em;
  color: black;
  opacity: 0.3; }

.icon-worker .path3:before {
  content: "";
  margin-left: -1em;
  color: black; }

.icon-add:before {
  content: ""; }

header {
  text-align: right;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 2;
  justify-content: center;
  display: flex;
  align-items: center;
  height: 70px;
  padding-left: 210px;
  border-bottom: 1px solid rgba(12, 12, 12, 0.1);
  background-color: #F8F8F8; }
  header .right-side .icon {
    color: #179DFF; }

.sidebar-open-btn {
  position: absolute;
  top: 50%;
  left: 25px;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%); }

.sidebar-open-btn {
  display: none; }

.sidebar {
  position: fixed;
  width: 210px;
  top: 0;
  left: 0;
  bottom: 0;
  z-index: 2;
  border-right: 1px solid rgba(12, 12, 12, 0.1);
  box-shadow: 0 3px 3px 0 rgba(0, 0, 0, 0.08);
  background-color: #fff; }
  .sidebar .logout {
    display: none; }

.sidebar-logo {
  height: 70px;
  border-bottom: 1px solid rgba(12, 12, 12, 0.1);
  justify-content: center;
  display: flex;
  align-items: center; }

.sidebar-content {
  padding-top: 40px;
  height: calc(100vh - 70px);
  overflow: auto; }
  .sidebar-content .menu li {
    position: relative; }
    .sidebar-content .menu li a {
      display: block;
      padding: 12px 10px 12px 65px;
      font-weight: 700;
      opacity: .7;
      -webkit-transition: all .3s;
      transition: all .3s;
      font-size: 16px; }
      .sidebar-content .menu li a:hover {
        opacity: 1; }
      .sidebar-content .menu li a.active {
        opacity: 1;
        color: #179DFF;
        position: relative; }
        .sidebar-content .menu li a.active .icon > span:before {
          color: #179DFF; }
        .sidebar-content .menu li a.active:before {
          content: "";
          position: absolute;
          height: 100%;
          width: 5px;
          left: 0;
          top: 0;
          background-color: #179DFF;
          border-radius: 40px; }
  .sidebar-content .menu .icon {
    position: absolute;
    left: 25px;
    top: 50%;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%); }

.sidebar-left-btn {
  position: absolute;
  right: -40px;
  top: 20px;
  display: none;
  opacity: 0; }

.sidebar-right {
  width: 540px;
  position: fixed;
  z-index: 4;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: #fff;
  border-left: 1px solid rgba(12, 12, 12, 0.1);
  padding: 35px 60px;
  -webkit-transform: translateX(540px);
          transform: translateX(540px);
  -webkit-transition: all .3s;
  transition: all .3s;
  overflow-y: auto; }
  .sidebar-right.show {
    -webkit-transform: translateX(0);
            transform: translateX(0); }
  .sidebar-right .sidebar-img {
    max-height: 180px;
    max-width: 100%; }

.sidebar-right-inner {
  min-height: 100%;
  padding-bottom: 80px;
  position: relative; }

.sidebar-right-btn {
  display: none;
  position: absolute;
  top: -27px;
  right: -27px; }

.sidebar-meta {
  display: flex;
  justify-content: flex-end;
  position: absolute;
  bottom: 0;
  right: 0;
  left: 0; }

#main {
  position: relative; }
  #main:after {
    content: "";
    -webkit-transition: all .3s;
    transition: all .3s; }
  #main.overlay {
    overflow: hidden;
    position: relative; }
    #main.overlay:after {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: rgba(0, 0, 0, 0.7);
      z-index: 2; }

#mainDiv {
  position: relative; }
  #mainDiv.overlayDiv:after {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 3; }

.page-wrapper {
  min-height: 100vh;
  padding: 70px 0 0 210px;
  background-color: #F8F8F8; }

.link {
  color: #179DFF;
  text-decoration: none;
  font-size: 16px; }
  .link:not(.color-white):not(.color-warning):hover, .link:not(.color-white):not(.color-warning):focus {
    color: #0092fd; }
  .link.with-icon .icon {
    vertical-align: middle;
    margin-right: 7px; }

.pointer {
  cursor: pointer; }

.icon {
  font-size: 22px; }
  .icon span {
    display: inline-block; }
  .icon.blue:before, .icon.blue [class^="path"]:before {
    color: #179DFF; }
  .icon.green:before, .icon.green [class^="path"]:before {
    color: #26BE7C; }
  .icon.red:before, .icon.red [class^="path"]:before {
    color: #ca3838; }
  .icon.white:before, .icon.white [class^="path"]:before {
    color: #fff; }
  .icon.color-text [class^="path"]:before {
    color: rgba(12, 12, 12, 0.7); }

.disabled {
  opacity: .6; }

.border-top {
  border-top: 1px solid rgba(12, 12, 12, 0.1); }

.border-bottom {
  border-bottom: 1px solid rgba(12, 12, 12, 0.1); }

.border-left {
  border-left: 1px solid rgba(12, 12, 12, 0.1); }

.border-right {
  border-right: 1px solid rgba(12, 12, 12, 0.1); }

@media (min-width: 360px) {
  .float-xxs-left {
    float: left; }
  .float-xxs-right {
    float: right; } }

@media (min-width: 500px) {
  .float-xs-left {
    float: left; }
  .float-xs-right {
    float: right; } }

@media (min-width: 768px) {
  .float-sm-left {
    float: left; }
  .float-sm-right {
    float: right; } }

@media (min-width: 1024px) {
  .float-md-left {
    float: left; }
  .float-md-right {
    float: right; } }

@media (min-width: 1200px) {
  .float-lg-left {
    float: left; }
  .float-lg-right {
    float: right; } }

.clear-after::after {
  content: " ";
  clear: both;
  display: block; }

.full-width {
  width: 100%; }

.full-height {
  height: 100%; }

.flex {
  display: flex; }

.inline-flex {
  display: inline-flex; }

.wrap {
  flex-wrap: wrap; }

.block {
  display: block; }

.inline-block {
  display: inline-block; }

/*alignments*/
.vertical-middle {
  vertical-align: middle; }

.pull-right {
  margin-left: auto; }

.pull-left {
  margin-right: auto; }

/*ellipsis*/
.ellipsis {
  max-width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis; }

/*positions*/
.pos-rel {
  position: relative; }

.pos-abs {
  position: absolute; }

.justify-center {
  justify-content: center; }

.justify-start {
  justify-content: flex-start; }

.justify-end {
  justify-content: flex-end; }

.justify-around {
  justify-content: space-around; }

.justify-between {
  justify-content: space-between; }

.content-center {
  align-content: center; }

.content-start {
  align-content: flex-start; }

.content-end {
  align-content: flex-end; }

.content-around {
  align-content: space-around; }

.content-between {
  align-content: space-between; }

.align-center {
  align-items: center; }

.align-start {
  align-items: flex-start; }

.align-end {
  align-items: flex-end; }

.flex-center-x {
  display: flex;
  justify-content: center; }

.flex-center-y {
  display: flex;
  align-items: center; }

.direction-column {
  flex-direction: column; }

@media (max-width: 359px) {
  .hide-xxs {
    display: none; } }

@media (max-width: 499px) {
  .hide-xs {
    display: none; } }

@media (max-width: 767px) {
  .hide-sm {
    display: none; } }

@media (max-width: 1023px) {
  .hide-md {
    display: none; } }

@media (max-width: 1199px) {
  .hide-lg {
    display: none; } }

@media (min-width: 360px) {
  .cols-xxs-12 {
    flex-wrap: wrap;
    display: flex; }
    .cols-xxs-12 > [class^="col"]:not(.ml-0), .cols-xxs-12 > [class*=" col"]:not(.ml-0) {
      margin-left: 10px; }
    .cols-xxs-12 > [class^="col"], .cols-xxs-12 > [class*=" col"] {
      width: calc(8.33333% - 9.16667px); }
      .cols-xxs-12 > [class^="col"]:nth-child(12n + 1), .cols-xxs-12 > [class*=" col"]:nth-child(12n + 1) {
        margin-left: 0; }
  .cols-xxs-11 {
    flex-wrap: wrap;
    display: flex; }
    .cols-xxs-11 > [class^="col"]:not(.ml-0), .cols-xxs-11 > [class*=" col"]:not(.ml-0) {
      margin-left: 10px; }
    .cols-xxs-11 > [class^="col"], .cols-xxs-11 > [class*=" col"] {
      width: calc(9.09091% - 9.09091px); }
      .cols-xxs-11 > [class^="col"]:nth-child(11n + 1), .cols-xxs-11 > [class*=" col"]:nth-child(11n + 1) {
        margin-left: 0; }
  .cols-xxs-10 {
    flex-wrap: wrap;
    display: flex; }
    .cols-xxs-10 > [class^="col"]:not(.ml-0), .cols-xxs-10 > [class*=" col"]:not(.ml-0) {
      margin-left: 10px; }
    .cols-xxs-10 > [class^="col"], .cols-xxs-10 > [class*=" col"] {
      width: calc(10% - 9px); }
      .cols-xxs-10 > [class^="col"]:nth-child(10n + 1), .cols-xxs-10 > [class*=" col"]:nth-child(10n + 1) {
        margin-left: 0; }
  .cols-xxs-9 {
    flex-wrap: wrap;
    display: flex; }
    .cols-xxs-9 > [class^="col"]:not(.ml-0), .cols-xxs-9 > [class*=" col"]:not(.ml-0) {
      margin-left: 10px; }
    .cols-xxs-9 > [class^="col"], .cols-xxs-9 > [class*=" col"] {
      width: calc(11.11111% - 8.88889px); }
      .cols-xxs-9 > [class^="col"]:nth-child(9n + 1), .cols-xxs-9 > [class*=" col"]:nth-child(9n + 1) {
        margin-left: 0; }
  .cols-xxs-8 {
    flex-wrap: wrap;
    display: flex; }
    .cols-xxs-8 > [class^="col"]:not(.ml-0), .cols-xxs-8 > [class*=" col"]:not(.ml-0) {
      margin-left: 10px; }
    .cols-xxs-8 > [class^="col"], .cols-xxs-8 > [class*=" col"] {
      width: calc(12.5% - 8.75px); }
      .cols-xxs-8 > [class^="col"]:nth-child(8n + 1), .cols-xxs-8 > [class*=" col"]:nth-child(8n + 1) {
        margin-left: 0; }
  .cols-xxs-7 {
    flex-wrap: wrap;
    display: flex; }
    .cols-xxs-7 > [class^="col"]:not(.ml-0), .cols-xxs-7 > [class*=" col"]:not(.ml-0) {
      margin-left: 10px; }
    .cols-xxs-7 > [class^="col"], .cols-xxs-7 > [class*=" col"] {
      width: calc(14.28571% - 8.57143px); }
      .cols-xxs-7 > [class^="col"]:nth-child(7n + 1), .cols-xxs-7 > [class*=" col"]:nth-child(7n + 1) {
        margin-left: 0; }
  .cols-xxs-6 {
    flex-wrap: wrap;
    display: flex; }
    .cols-xxs-6 > [class^="col"]:not(.ml-0), .cols-xxs-6 > [class*=" col"]:not(.ml-0) {
      margin-left: 10px; }
    .cols-xxs-6 > [class^="col"], .cols-xxs-6 > [class*=" col"] {
      width: calc(16.66667% - 8.33333px); }
      .cols-xxs-6 > [class^="col"]:nth-child(6n + 1), .cols-xxs-6 > [class*=" col"]:nth-child(6n + 1) {
        margin-left: 0; }
  .cols-xxs-5 {
    flex-wrap: wrap;
    display: flex; }
    .cols-xxs-5 > [class^="col"]:not(.ml-0), .cols-xxs-5 > [class*=" col"]:not(.ml-0) {
      margin-left: 10px; }
    .cols-xxs-5 > [class^="col"], .cols-xxs-5 > [class*=" col"] {
      width: calc(20% - 8px); }
      .cols-xxs-5 > [class^="col"]:nth-child(5n + 1), .cols-xxs-5 > [class*=" col"]:nth-child(5n + 1) {
        margin-left: 0; }
  .cols-xxs-4 {
    flex-wrap: wrap;
    display: flex; }
    .cols-xxs-4 > [class^="col"]:not(.ml-0), .cols-xxs-4 > [class*=" col"]:not(.ml-0) {
      margin-left: 10px; }
    .cols-xxs-4 > [class^="col"], .cols-xxs-4 > [class*=" col"] {
      width: calc(25% - 7.5px); }
      .cols-xxs-4 > [class^="col"]:nth-child(4n + 1), .cols-xxs-4 > [class*=" col"]:nth-child(4n + 1) {
        margin-left: 0; }
  .cols-xxs-3 {
    flex-wrap: wrap;
    display: flex; }
    .cols-xxs-3 > [class^="col"]:not(.ml-0), .cols-xxs-3 > [class*=" col"]:not(.ml-0) {
      margin-left: 10px; }
    .cols-xxs-3 > [class^="col"], .cols-xxs-3 > [class*=" col"] {
      width: calc(33.33333% - 6.66667px); }
      .cols-xxs-3 > [class^="col"]:nth-child(3n + 1), .cols-xxs-3 > [class*=" col"]:nth-child(3n + 1) {
        margin-left: 0; }
  .cols-xxs-2 {
    flex-wrap: wrap;
    display: flex; }
    .cols-xxs-2 > [class^="col"]:not(.ml-0), .cols-xxs-2 > [class*=" col"]:not(.ml-0) {
      margin-left: 10px; }
    .cols-xxs-2 > [class^="col"], .cols-xxs-2 > [class*=" col"] {
      width: calc(50% - 5px); }
      .cols-xxs-2 > [class^="col"]:nth-child(2n + 1), .cols-xxs-2 > [class*=" col"]:nth-child(2n + 1) {
        margin-left: 0; } }

@media (min-width: 500px) {
  .cols-xs-12 {
    flex-wrap: wrap;
    display: flex; }
    .cols-xs-12 > [class^="col"]:not(.ml-0), .cols-xs-12 > [class*=" col"]:not(.ml-0) {
      margin-left: 10px; }
    .cols-xs-12 > [class^="col"], .cols-xs-12 > [class*=" col"] {
      width: calc(8.33333% - 9.16667px); }
      .cols-xs-12 > [class^="col"]:nth-child(12n + 1), .cols-xs-12 > [class*=" col"]:nth-child(12n + 1) {
        margin-left: 0; }
  .cols-xs-11 {
    flex-wrap: wrap;
    display: flex; }
    .cols-xs-11 > [class^="col"]:not(.ml-0), .cols-xs-11 > [class*=" col"]:not(.ml-0) {
      margin-left: 10px; }
    .cols-xs-11 > [class^="col"], .cols-xs-11 > [class*=" col"] {
      width: calc(9.09091% - 9.09091px); }
      .cols-xs-11 > [class^="col"]:nth-child(11n + 1), .cols-xs-11 > [class*=" col"]:nth-child(11n + 1) {
        margin-left: 0; }
  .cols-xs-10 {
    flex-wrap: wrap;
    display: flex; }
    .cols-xs-10 > [class^="col"]:not(.ml-0), .cols-xs-10 > [class*=" col"]:not(.ml-0) {
      margin-left: 10px; }
    .cols-xs-10 > [class^="col"], .cols-xs-10 > [class*=" col"] {
      width: calc(10% - 9px); }
      .cols-xs-10 > [class^="col"]:nth-child(10n + 1), .cols-xs-10 > [class*=" col"]:nth-child(10n + 1) {
        margin-left: 0; }
  .cols-xs-9 {
    flex-wrap: wrap;
    display: flex; }
    .cols-xs-9 > [class^="col"]:not(.ml-0), .cols-xs-9 > [class*=" col"]:not(.ml-0) {
      margin-left: 10px; }
    .cols-xs-9 > [class^="col"], .cols-xs-9 > [class*=" col"] {
      width: calc(11.11111% - 8.88889px); }
      .cols-xs-9 > [class^="col"]:nth-child(9n + 1), .cols-xs-9 > [class*=" col"]:nth-child(9n + 1) {
        margin-left: 0; }
  .cols-xs-8 {
    flex-wrap: wrap;
    display: flex; }
    .cols-xs-8 > [class^="col"]:not(.ml-0), .cols-xs-8 > [class*=" col"]:not(.ml-0) {
      margin-left: 10px; }
    .cols-xs-8 > [class^="col"], .cols-xs-8 > [class*=" col"] {
      width: calc(12.5% - 8.75px); }
      .cols-xs-8 > [class^="col"]:nth-child(8n + 1), .cols-xs-8 > [class*=" col"]:nth-child(8n + 1) {
        margin-left: 0; }
  .cols-xs-7 {
    flex-wrap: wrap;
    display: flex; }
    .cols-xs-7 > [class^="col"]:not(.ml-0), .cols-xs-7 > [class*=" col"]:not(.ml-0) {
      margin-left: 10px; }
    .cols-xs-7 > [class^="col"], .cols-xs-7 > [class*=" col"] {
      width: calc(14.28571% - 8.57143px); }
      .cols-xs-7 > [class^="col"]:nth-child(7n + 1), .cols-xs-7 > [class*=" col"]:nth-child(7n + 1) {
        margin-left: 0; }
  .cols-xs-6 {
    flex-wrap: wrap;
    display: flex; }
    .cols-xs-6 > [class^="col"]:not(.ml-0), .cols-xs-6 > [class*=" col"]:not(.ml-0) {
      margin-left: 10px; }
    .cols-xs-6 > [class^="col"], .cols-xs-6 > [class*=" col"] {
      width: calc(16.66667% - 8.33333px); }
      .cols-xs-6 > [class^="col"]:nth-child(6n + 1), .cols-xs-6 > [class*=" col"]:nth-child(6n + 1) {
        margin-left: 0; }
  .cols-xs-5 {
    flex-wrap: wrap;
    display: flex; }
    .cols-xs-5 > [class^="col"]:not(.ml-0), .cols-xs-5 > [class*=" col"]:not(.ml-0) {
      margin-left: 10px; }
    .cols-xs-5 > [class^="col"], .cols-xs-5 > [class*=" col"] {
      width: calc(20% - 8px); }
      .cols-xs-5 > [class^="col"]:nth-child(5n + 1), .cols-xs-5 > [class*=" col"]:nth-child(5n + 1) {
        margin-left: 0; }
  .cols-xs-4 {
    flex-wrap: wrap;
    display: flex; }
    .cols-xs-4 > [class^="col"]:not(.ml-0), .cols-xs-4 > [class*=" col"]:not(.ml-0) {
      margin-left: 10px; }
    .cols-xs-4 > [class^="col"], .cols-xs-4 > [class*=" col"] {
      width: calc(25% - 7.5px); }
      .cols-xs-4 > [class^="col"]:nth-child(4n + 1), .cols-xs-4 > [class*=" col"]:nth-child(4n + 1) {
        margin-left: 0; }
  .cols-xs-3 {
    flex-wrap: wrap;
    display: flex; }
    .cols-xs-3 > [class^="col"]:not(.ml-0), .cols-xs-3 > [class*=" col"]:not(.ml-0) {
      margin-left: 10px; }
    .cols-xs-3 > [class^="col"], .cols-xs-3 > [class*=" col"] {
      width: calc(33.33333% - 6.66667px); }
      .cols-xs-3 > [class^="col"]:nth-child(3n + 1), .cols-xs-3 > [class*=" col"]:nth-child(3n + 1) {
        margin-left: 0; }
  .cols-xs-2 {
    flex-wrap: wrap;
    display: flex; }
    .cols-xs-2 > [class^="col"]:not(.ml-0), .cols-xs-2 > [class*=" col"]:not(.ml-0) {
      margin-left: 10px; }
    .cols-xs-2 > [class^="col"], .cols-xs-2 > [class*=" col"] {
      width: calc(50% - 5px); }
      .cols-xs-2 > [class^="col"]:nth-child(2n + 1), .cols-xs-2 > [class*=" col"]:nth-child(2n + 1) {
        margin-left: 0; } }

@media (min-width: 768px) {
  .cols-sm-12 {
    flex-wrap: wrap;
    display: flex; }
    .cols-sm-12 > [class^="col"]:not(.ml-0), .cols-sm-12 > [class*=" col"]:not(.ml-0) {
      margin-left: 10px; }
    .cols-sm-12 > [class^="col"], .cols-sm-12 > [class*=" col"] {
      width: calc(8.33333% - 9.16667px); }
      .cols-sm-12 > [class^="col"]:nth-child(12n + 1), .cols-sm-12 > [class*=" col"]:nth-child(12n + 1) {
        margin-left: 0; }
  .cols-sm-11 {
    flex-wrap: wrap;
    display: flex; }
    .cols-sm-11 > [class^="col"]:not(.ml-0), .cols-sm-11 > [class*=" col"]:not(.ml-0) {
      margin-left: 10px; }
    .cols-sm-11 > [class^="col"], .cols-sm-11 > [class*=" col"] {
      width: calc(9.09091% - 9.09091px); }
      .cols-sm-11 > [class^="col"]:nth-child(11n + 1), .cols-sm-11 > [class*=" col"]:nth-child(11n + 1) {
        margin-left: 0; }
  .cols-sm-10 {
    flex-wrap: wrap;
    display: flex; }
    .cols-sm-10 > [class^="col"]:not(.ml-0), .cols-sm-10 > [class*=" col"]:not(.ml-0) {
      margin-left: 10px; }
    .cols-sm-10 > [class^="col"], .cols-sm-10 > [class*=" col"] {
      width: calc(10% - 9px); }
      .cols-sm-10 > [class^="col"]:nth-child(10n + 1), .cols-sm-10 > [class*=" col"]:nth-child(10n + 1) {
        margin-left: 0; }
  .cols-sm-9 {
    flex-wrap: wrap;
    display: flex; }
    .cols-sm-9 > [class^="col"]:not(.ml-0), .cols-sm-9 > [class*=" col"]:not(.ml-0) {
      margin-left: 10px; }
    .cols-sm-9 > [class^="col"], .cols-sm-9 > [class*=" col"] {
      width: calc(11.11111% - 8.88889px); }
      .cols-sm-9 > [class^="col"]:nth-child(9n + 1), .cols-sm-9 > [class*=" col"]:nth-child(9n + 1) {
        margin-left: 0; }
  .cols-sm-8 {
    flex-wrap: wrap;
    display: flex; }
    .cols-sm-8 > [class^="col"]:not(.ml-0), .cols-sm-8 > [class*=" col"]:not(.ml-0) {
      margin-left: 10px; }
    .cols-sm-8 > [class^="col"], .cols-sm-8 > [class*=" col"] {
      width: calc(12.5% - 8.75px); }
      .cols-sm-8 > [class^="col"]:nth-child(8n + 1), .cols-sm-8 > [class*=" col"]:nth-child(8n + 1) {
        margin-left: 0; }
  .cols-sm-7 {
    flex-wrap: wrap;
    display: flex; }
    .cols-sm-7 > [class^="col"]:not(.ml-0), .cols-sm-7 > [class*=" col"]:not(.ml-0) {
      margin-left: 10px; }
    .cols-sm-7 > [class^="col"], .cols-sm-7 > [class*=" col"] {
      width: calc(14.28571% - 8.57143px); }
      .cols-sm-7 > [class^="col"]:nth-child(7n + 1), .cols-sm-7 > [class*=" col"]:nth-child(7n + 1) {
        margin-left: 0; }
  .cols-sm-6 {
    flex-wrap: wrap;
    display: flex; }
    .cols-sm-6 > [class^="col"]:not(.ml-0), .cols-sm-6 > [class*=" col"]:not(.ml-0) {
      margin-left: 10px; }
    .cols-sm-6 > [class^="col"], .cols-sm-6 > [class*=" col"] {
      width: calc(16.66667% - 8.33333px); }
      .cols-sm-6 > [class^="col"]:nth-child(6n + 1), .cols-sm-6 > [class*=" col"]:nth-child(6n + 1) {
        margin-left: 0; }
  .cols-sm-5 {
    flex-wrap: wrap;
    display: flex; }
    .cols-sm-5 > [class^="col"]:not(.ml-0), .cols-sm-5 > [class*=" col"]:not(.ml-0) {
      margin-left: 10px; }
    .cols-sm-5 > [class^="col"], .cols-sm-5 > [class*=" col"] {
      width: calc(20% - 8px); }
      .cols-sm-5 > [class^="col"]:nth-child(5n + 1), .cols-sm-5 > [class*=" col"]:nth-child(5n + 1) {
        margin-left: 0; }
  .cols-sm-4 {
    flex-wrap: wrap;
    display: flex; }
    .cols-sm-4 > [class^="col"]:not(.ml-0), .cols-sm-4 > [class*=" col"]:not(.ml-0) {
      margin-left: 10px; }
    .cols-sm-4 > [class^="col"], .cols-sm-4 > [class*=" col"] {
      width: calc(25% - 7.5px); }
      .cols-sm-4 > [class^="col"]:nth-child(4n + 1), .cols-sm-4 > [class*=" col"]:nth-child(4n + 1) {
        margin-left: 0; }
  .cols-sm-3 {
    flex-wrap: wrap;
    display: flex; }
    .cols-sm-3 > [class^="col"]:not(.ml-0), .cols-sm-3 > [class*=" col"]:not(.ml-0) {
      margin-left: 10px; }
    .cols-sm-3 > [class^="col"], .cols-sm-3 > [class*=" col"] {
      width: calc(33.33333% - 6.66667px); }
      .cols-sm-3 > [class^="col"]:nth-child(3n + 1), .cols-sm-3 > [class*=" col"]:nth-child(3n + 1) {
        margin-left: 0; }
  .cols-sm-2 {
    flex-wrap: wrap;
    display: flex; }
    .cols-sm-2 > [class^="col"]:not(.ml-0), .cols-sm-2 > [class*=" col"]:not(.ml-0) {
      margin-left: 10px; }
    .cols-sm-2 > [class^="col"], .cols-sm-2 > [class*=" col"] {
      width: calc(50% - 5px); }
      .cols-sm-2 > [class^="col"]:nth-child(2n + 1), .cols-sm-2 > [class*=" col"]:nth-child(2n + 1) {
        margin-left: 0; } }

@media (min-width: 1024px) {
  .cols-md-12 {
    flex-wrap: wrap;
    display: flex; }
    .cols-md-12 > [class^="col"]:not(.ml-0), .cols-md-12 > [class*=" col"]:not(.ml-0) {
      margin-left: 10px; }
    .cols-md-12 > [class^="col"], .cols-md-12 > [class*=" col"] {
      width: calc(8.33333% - 9.16667px); }
      .cols-md-12 > [class^="col"]:nth-child(12n + 1), .cols-md-12 > [class*=" col"]:nth-child(12n + 1) {
        margin-left: 0; }
  .cols-md-11 {
    flex-wrap: wrap;
    display: flex; }
    .cols-md-11 > [class^="col"]:not(.ml-0), .cols-md-11 > [class*=" col"]:not(.ml-0) {
      margin-left: 10px; }
    .cols-md-11 > [class^="col"], .cols-md-11 > [class*=" col"] {
      width: calc(9.09091% - 9.09091px); }
      .cols-md-11 > [class^="col"]:nth-child(11n + 1), .cols-md-11 > [class*=" col"]:nth-child(11n + 1) {
        margin-left: 0; }
  .cols-md-10 {
    flex-wrap: wrap;
    display: flex; }
    .cols-md-10 > [class^="col"]:not(.ml-0), .cols-md-10 > [class*=" col"]:not(.ml-0) {
      margin-left: 10px; }
    .cols-md-10 > [class^="col"], .cols-md-10 > [class*=" col"] {
      width: calc(10% - 9px); }
      .cols-md-10 > [class^="col"]:nth-child(10n + 1), .cols-md-10 > [class*=" col"]:nth-child(10n + 1) {
        margin-left: 0; }
  .cols-md-9 {
    flex-wrap: wrap;
    display: flex; }
    .cols-md-9 > [class^="col"]:not(.ml-0), .cols-md-9 > [class*=" col"]:not(.ml-0) {
      margin-left: 10px; }
    .cols-md-9 > [class^="col"], .cols-md-9 > [class*=" col"] {
      width: calc(11.11111% - 8.88889px); }
      .cols-md-9 > [class^="col"]:nth-child(9n + 1), .cols-md-9 > [class*=" col"]:nth-child(9n + 1) {
        margin-left: 0; }
  .cols-md-8 {
    flex-wrap: wrap;
    display: flex; }
    .cols-md-8 > [class^="col"]:not(.ml-0), .cols-md-8 > [class*=" col"]:not(.ml-0) {
      margin-left: 10px; }
    .cols-md-8 > [class^="col"], .cols-md-8 > [class*=" col"] {
      width: calc(12.5% - 8.75px); }
      .cols-md-8 > [class^="col"]:nth-child(8n + 1), .cols-md-8 > [class*=" col"]:nth-child(8n + 1) {
        margin-left: 0; }
  .cols-md-7 {
    flex-wrap: wrap;
    display: flex; }
    .cols-md-7 > [class^="col"]:not(.ml-0), .cols-md-7 > [class*=" col"]:not(.ml-0) {
      margin-left: 10px; }
    .cols-md-7 > [class^="col"], .cols-md-7 > [class*=" col"] {
      width: calc(14.28571% - 8.57143px); }
      .cols-md-7 > [class^="col"]:nth-child(7n + 1), .cols-md-7 > [class*=" col"]:nth-child(7n + 1) {
        margin-left: 0; }
  .cols-md-6 {
    flex-wrap: wrap;
    display: flex; }
    .cols-md-6 > [class^="col"]:not(.ml-0), .cols-md-6 > [class*=" col"]:not(.ml-0) {
      margin-left: 10px; }
    .cols-md-6 > [class^="col"], .cols-md-6 > [class*=" col"] {
      width: calc(16.66667% - 8.33333px); }
      .cols-md-6 > [class^="col"]:nth-child(6n + 1), .cols-md-6 > [class*=" col"]:nth-child(6n + 1) {
        margin-left: 0; }
  .cols-md-5 {
    flex-wrap: wrap;
    display: flex; }
    .cols-md-5 > [class^="col"]:not(.ml-0), .cols-md-5 > [class*=" col"]:not(.ml-0) {
      margin-left: 10px; }
    .cols-md-5 > [class^="col"], .cols-md-5 > [class*=" col"] {
      width: calc(20% - 8px); }
      .cols-md-5 > [class^="col"]:nth-child(5n + 1), .cols-md-5 > [class*=" col"]:nth-child(5n + 1) {
        margin-left: 0; }
  .cols-md-4 {
    flex-wrap: wrap;
    display: flex; }
    .cols-md-4 > [class^="col"]:not(.ml-0), .cols-md-4 > [class*=" col"]:not(.ml-0) {
      margin-left: 10px; }
    .cols-md-4 > [class^="col"], .cols-md-4 > [class*=" col"] {
      width: calc(25% - 7.5px); }
      .cols-md-4 > [class^="col"]:nth-child(4n + 1), .cols-md-4 > [class*=" col"]:nth-child(4n + 1) {
        margin-left: 0; }
  .cols-md-3 {
    flex-wrap: wrap;
    display: flex; }
    .cols-md-3 > [class^="col"]:not(.ml-0), .cols-md-3 > [class*=" col"]:not(.ml-0) {
      margin-left: 10px; }
    .cols-md-3 > [class^="col"], .cols-md-3 > [class*=" col"] {
      width: calc(33.33333% - 6.66667px); }
      .cols-md-3 > [class^="col"]:nth-child(3n + 1), .cols-md-3 > [class*=" col"]:nth-child(3n + 1) {
        margin-left: 0; }
  .cols-md-2 {
    flex-wrap: wrap;
    display: flex; }
    .cols-md-2 > [class^="col"]:not(.ml-0), .cols-md-2 > [class*=" col"]:not(.ml-0) {
      margin-left: 10px; }
    .cols-md-2 > [class^="col"], .cols-md-2 > [class*=" col"] {
      width: calc(50% - 5px); }
      .cols-md-2 > [class^="col"]:nth-child(2n + 1), .cols-md-2 > [class*=" col"]:nth-child(2n + 1) {
        margin-left: 0; } }

@media (min-width: 1200px) {
  .cols-lg-12 {
    flex-wrap: wrap;
    display: flex; }
    .cols-lg-12 > [class^="col"]:not(.ml-0), .cols-lg-12 > [class*=" col"]:not(.ml-0) {
      margin-left: 10px; }
    .cols-lg-12 > [class^="col"], .cols-lg-12 > [class*=" col"] {
      width: calc(8.33333% - 9.16667px); }
      .cols-lg-12 > [class^="col"]:nth-child(12n + 1), .cols-lg-12 > [class*=" col"]:nth-child(12n + 1) {
        margin-left: 0; }
  .cols-lg-11 {
    flex-wrap: wrap;
    display: flex; }
    .cols-lg-11 > [class^="col"]:not(.ml-0), .cols-lg-11 > [class*=" col"]:not(.ml-0) {
      margin-left: 10px; }
    .cols-lg-11 > [class^="col"], .cols-lg-11 > [class*=" col"] {
      width: calc(9.09091% - 9.09091px); }
      .cols-lg-11 > [class^="col"]:nth-child(11n + 1), .cols-lg-11 > [class*=" col"]:nth-child(11n + 1) {
        margin-left: 0; }
  .cols-lg-10 {
    flex-wrap: wrap;
    display: flex; }
    .cols-lg-10 > [class^="col"]:not(.ml-0), .cols-lg-10 > [class*=" col"]:not(.ml-0) {
      margin-left: 10px; }
    .cols-lg-10 > [class^="col"], .cols-lg-10 > [class*=" col"] {
      width: calc(10% - 9px); }
      .cols-lg-10 > [class^="col"]:nth-child(10n + 1), .cols-lg-10 > [class*=" col"]:nth-child(10n + 1) {
        margin-left: 0; }
  .cols-lg-9 {
    flex-wrap: wrap;
    display: flex; }
    .cols-lg-9 > [class^="col"]:not(.ml-0), .cols-lg-9 > [class*=" col"]:not(.ml-0) {
      margin-left: 10px; }
    .cols-lg-9 > [class^="col"], .cols-lg-9 > [class*=" col"] {
      width: calc(11.11111% - 8.88889px); }
      .cols-lg-9 > [class^="col"]:nth-child(9n + 1), .cols-lg-9 > [class*=" col"]:nth-child(9n + 1) {
        margin-left: 0; }
  .cols-lg-8 {
    flex-wrap: wrap;
    display: flex; }
    .cols-lg-8 > [class^="col"]:not(.ml-0), .cols-lg-8 > [class*=" col"]:not(.ml-0) {
      margin-left: 10px; }
    .cols-lg-8 > [class^="col"], .cols-lg-8 > [class*=" col"] {
      width: calc(12.5% - 8.75px); }
      .cols-lg-8 > [class^="col"]:nth-child(8n + 1), .cols-lg-8 > [class*=" col"]:nth-child(8n + 1) {
        margin-left: 0; }
  .cols-lg-7 {
    flex-wrap: wrap;
    display: flex; }
    .cols-lg-7 > [class^="col"]:not(.ml-0), .cols-lg-7 > [class*=" col"]:not(.ml-0) {
      margin-left: 10px; }
    .cols-lg-7 > [class^="col"], .cols-lg-7 > [class*=" col"] {
      width: calc(14.28571% - 8.57143px); }
      .cols-lg-7 > [class^="col"]:nth-child(7n + 1), .cols-lg-7 > [class*=" col"]:nth-child(7n + 1) {
        margin-left: 0; }
  .cols-lg-6 {
    flex-wrap: wrap;
    display: flex; }
    .cols-lg-6 > [class^="col"]:not(.ml-0), .cols-lg-6 > [class*=" col"]:not(.ml-0) {
      margin-left: 10px; }
    .cols-lg-6 > [class^="col"], .cols-lg-6 > [class*=" col"] {
      width: calc(16.66667% - 8.33333px); }
      .cols-lg-6 > [class^="col"]:nth-child(6n + 1), .cols-lg-6 > [class*=" col"]:nth-child(6n + 1) {
        margin-left: 0; }
  .cols-lg-5 {
    flex-wrap: wrap;
    display: flex; }
    .cols-lg-5 > [class^="col"]:not(.ml-0), .cols-lg-5 > [class*=" col"]:not(.ml-0) {
      margin-left: 10px; }
    .cols-lg-5 > [class^="col"], .cols-lg-5 > [class*=" col"] {
      width: calc(20% - 8px); }
      .cols-lg-5 > [class^="col"]:nth-child(5n + 1), .cols-lg-5 > [class*=" col"]:nth-child(5n + 1) {
        margin-left: 0; }
  .cols-lg-4 {
    flex-wrap: wrap;
    display: flex; }
    .cols-lg-4 > [class^="col"]:not(.ml-0), .cols-lg-4 > [class*=" col"]:not(.ml-0) {
      margin-left: 10px; }
    .cols-lg-4 > [class^="col"], .cols-lg-4 > [class*=" col"] {
      width: calc(25% - 7.5px); }
      .cols-lg-4 > [class^="col"]:nth-child(4n + 1), .cols-lg-4 > [class*=" col"]:nth-child(4n + 1) {
        margin-left: 0; }
  .cols-lg-3 {
    flex-wrap: wrap;
    display: flex; }
    .cols-lg-3 > [class^="col"]:not(.ml-0), .cols-lg-3 > [class*=" col"]:not(.ml-0) {
      margin-left: 10px; }
    .cols-lg-3 > [class^="col"], .cols-lg-3 > [class*=" col"] {
      width: calc(33.33333% - 6.66667px); }
      .cols-lg-3 > [class^="col"]:nth-child(3n + 1), .cols-lg-3 > [class*=" col"]:nth-child(3n + 1) {
        margin-left: 0; }
  .cols-lg-2 {
    flex-wrap: wrap;
    display: flex; }
    .cols-lg-2 > [class^="col"]:not(.ml-0), .cols-lg-2 > [class*=" col"]:not(.ml-0) {
      margin-left: 10px; }
    .cols-lg-2 > [class^="col"], .cols-lg-2 > [class*=" col"] {
      width: calc(50% - 5px); }
      .cols-lg-2 > [class^="col"]:nth-child(2n + 1), .cols-lg-2 > [class*=" col"]:nth-child(2n + 1) {
        margin-left: 0; } }

@media (min-width: 360px) {
  [class^="cols-"] .col-xxs-12, [class*=" cols-"] .col-xxs-12 {
    width: calc(100% - 0px); }
  [class^="cols-"] .col-xxs-11, [class*=" cols-"] .col-xxs-11 {
    width: calc(91.66667% - 0.83333px); }
  [class^="cols-"] .col-xxs-10, [class*=" cols-"] .col-xxs-10 {
    width: calc(83.33333% - 1.66667px); }
  [class^="cols-"] .col-xxs-9, [class*=" cols-"] .col-xxs-9 {
    width: calc(75% - 2.5px); }
  [class^="cols-"] .col-xxs-8, [class*=" cols-"] .col-xxs-8 {
    width: calc(66.66667% - 3.33333px); }
  [class^="cols-"] .col-xxs-7, [class*=" cols-"] .col-xxs-7 {
    width: calc(58.33333% - 4.16667px); }
  [class^="cols-"] .col-xxs-6, [class*=" cols-"] .col-xxs-6 {
    width: calc(50% - 5px); }
  [class^="cols-"] .col-xxs-5, [class*=" cols-"] .col-xxs-5 {
    width: calc(41.66667% - 5.83333px); }
  [class^="cols-"] .col-xxs-4, [class*=" cols-"] .col-xxs-4 {
    width: calc(33.33333% - 6.66667px); }
  [class^="cols-"] .col-xxs-3, [class*=" cols-"] .col-xxs-3 {
    width: calc(25% - 7.5px); }
  [class^="cols-"] .col-xxs-2, [class*=" cols-"] .col-xxs-2 {
    width: calc(16.66667% - 8.33333px); } }

@media (min-width: 500px) {
  [class^="cols-"] .col-xs-12, [class*=" cols-"] .col-xs-12 {
    width: calc(100% - 0px); }
  [class^="cols-"] .col-xs-11, [class*=" cols-"] .col-xs-11 {
    width: calc(91.66667% - 0.83333px); }
  [class^="cols-"] .col-xs-10, [class*=" cols-"] .col-xs-10 {
    width: calc(83.33333% - 1.66667px); }
  [class^="cols-"] .col-xs-9, [class*=" cols-"] .col-xs-9 {
    width: calc(75% - 2.5px); }
  [class^="cols-"] .col-xs-8, [class*=" cols-"] .col-xs-8 {
    width: calc(66.66667% - 3.33333px); }
  [class^="cols-"] .col-xs-7, [class*=" cols-"] .col-xs-7 {
    width: calc(58.33333% - 4.16667px); }
  [class^="cols-"] .col-xs-6, [class*=" cols-"] .col-xs-6 {
    width: calc(50% - 5px); }
  [class^="cols-"] .col-xs-5, [class*=" cols-"] .col-xs-5 {
    width: calc(41.66667% - 5.83333px); }
  [class^="cols-"] .col-xs-4, [class*=" cols-"] .col-xs-4 {
    width: calc(33.33333% - 6.66667px); }
  [class^="cols-"] .col-xs-3, [class*=" cols-"] .col-xs-3 {
    width: calc(25% - 7.5px); }
  [class^="cols-"] .col-xs-2, [class*=" cols-"] .col-xs-2 {
    width: calc(16.66667% - 8.33333px); } }

@media (min-width: 768px) {
  [class^="cols-"] .col-sm-12, [class*=" cols-"] .col-sm-12 {
    width: calc(100% - 0px); }
  [class^="cols-"] .col-sm-11, [class*=" cols-"] .col-sm-11 {
    width: calc(91.66667% - 0.83333px); }
  [class^="cols-"] .col-sm-10, [class*=" cols-"] .col-sm-10 {
    width: calc(83.33333% - 1.66667px); }
  [class^="cols-"] .col-sm-9, [class*=" cols-"] .col-sm-9 {
    width: calc(75% - 2.5px); }
  [class^="cols-"] .col-sm-8, [class*=" cols-"] .col-sm-8 {
    width: calc(66.66667% - 3.33333px); }
  [class^="cols-"] .col-sm-7, [class*=" cols-"] .col-sm-7 {
    width: calc(58.33333% - 4.16667px); }
  [class^="cols-"] .col-sm-6, [class*=" cols-"] .col-sm-6 {
    width: calc(50% - 5px); }
  [class^="cols-"] .col-sm-5, [class*=" cols-"] .col-sm-5 {
    width: calc(41.66667% - 5.83333px); }
  [class^="cols-"] .col-sm-4, [class*=" cols-"] .col-sm-4 {
    width: calc(33.33333% - 6.66667px); }
  [class^="cols-"] .col-sm-3, [class*=" cols-"] .col-sm-3 {
    width: calc(25% - 7.5px); }
  [class^="cols-"] .col-sm-2, [class*=" cols-"] .col-sm-2 {
    width: calc(16.66667% - 8.33333px); } }

@media (min-width: 1024px) {
  [class^="cols-"] .col-md-12, [class*=" cols-"] .col-md-12 {
    width: calc(100% - 0px); }
  [class^="cols-"] .col-md-11, [class*=" cols-"] .col-md-11 {
    width: calc(91.66667% - 0.83333px); }
  [class^="cols-"] .col-md-10, [class*=" cols-"] .col-md-10 {
    width: calc(83.33333% - 1.66667px); }
  [class^="cols-"] .col-md-9, [class*=" cols-"] .col-md-9 {
    width: calc(75% - 2.5px); }
  [class^="cols-"] .col-md-8, [class*=" cols-"] .col-md-8 {
    width: calc(66.66667% - 3.33333px); }
  [class^="cols-"] .col-md-7, [class*=" cols-"] .col-md-7 {
    width: calc(58.33333% - 4.16667px); }
  [class^="cols-"] .col-md-6, [class*=" cols-"] .col-md-6 {
    width: calc(50% - 5px); }
  [class^="cols-"] .col-md-5, [class*=" cols-"] .col-md-5 {
    width: calc(41.66667% - 5.83333px); }
  [class^="cols-"] .col-md-4, [class*=" cols-"] .col-md-4 {
    width: calc(33.33333% - 6.66667px); }
  [class^="cols-"] .col-md-3, [class*=" cols-"] .col-md-3 {
    width: calc(25% - 7.5px); }
  [class^="cols-"] .col-md-2, [class*=" cols-"] .col-md-2 {
    width: calc(16.66667% - 8.33333px); } }

@media (min-width: 1200px) {
  [class^="cols-"] .col-lg-12, [class*=" cols-"] .col-lg-12 {
    width: calc(100% - 0px); }
  [class^="cols-"] .col-lg-11, [class*=" cols-"] .col-lg-11 {
    width: calc(91.66667% - 0.83333px); }
  [class^="cols-"] .col-lg-10, [class*=" cols-"] .col-lg-10 {
    width: calc(83.33333% - 1.66667px); }
  [class^="cols-"] .col-lg-9, [class*=" cols-"] .col-lg-9 {
    width: calc(75% - 2.5px); }
  [class^="cols-"] .col-lg-8, [class*=" cols-"] .col-lg-8 {
    width: calc(66.66667% - 3.33333px); }
  [class^="cols-"] .col-lg-7, [class*=" cols-"] .col-lg-7 {
    width: calc(58.33333% - 4.16667px); }
  [class^="cols-"] .col-lg-6, [class*=" cols-"] .col-lg-6 {
    width: calc(50% - 5px); }
  [class^="cols-"] .col-lg-5, [class*=" cols-"] .col-lg-5 {
    width: calc(41.66667% - 5.83333px); }
  [class^="cols-"] .col-lg-4, [class*=" cols-"] .col-lg-4 {
    width: calc(33.33333% - 6.66667px); }
  [class^="cols-"] .col-lg-3, [class*=" cols-"] .col-lg-3 {
    width: calc(25% - 7.5px); }
  [class^="cols-"] .col-lg-2, [class*=" cols-"] .col-lg-2 {
    width: calc(16.66667% - 8.33333px); } }

.cols-mb > [class^="col"], .cols-mb > [class*=" col"] {
  margin-bottom: 10px; }

@-webkit-keyframes circleGrow {
  0% {
    -webkit-transform: translateX(-50%) translateY(-50%) scale(0);
            transform: translateX(-50%) translateY(-50%) scale(0);
    opacity: 0; }
  20% {
    opacity: 1; }
  100% {
    -webkit-transform: translateX(-50%) translateY(-50%) scale(6);
            transform: translateX(-50%) translateY(-50%) scale(6);
    opacity: 0; } }

@keyframes circleGrow {
  0% {
    -webkit-transform: translateX(-50%) translateY(-50%) scale(0);
            transform: translateX(-50%) translateY(-50%) scale(0);
    opacity: 0; }
  20% {
    opacity: 1; }
  100% {
    -webkit-transform: translateX(-50%) translateY(-50%) scale(6);
            transform: translateX(-50%) translateY(-50%) scale(6);
    opacity: 0; } }

/*
 * Buttons global styles.
 */
button {
  -webkit-tap-highlight-color: transparent; }

.no-style {
  border: 0;
  padding: 0;
  background-color: transparent;
  outline: none;
  cursor: pointer; }
  .no-style:focus {
    outline: none; }

.btn {
  font-size: 16px;
  height: 46px;
  cursor: pointer;
  font-weight: 500;
  text-align: center;
  white-space: nowrap;
  display: inline-block;
  border-radius: 4px;
  outline: none;
  font-family: "Montserrat", sans-serif;
  -webkit-transition: all .3s;
  transition: all .3s;
  padding: 0 15px;
  min-width: 110px;
  max-width: 350px;
  /* Filled buttons */
  /* Outline buttons */
  /* Link buttons */
  /* Buttons size modifications */ }
  .btn.ripple {
    overflow: hidden; }
    .btn.ripple span {
      display: inline-block;
      position: relative;
      z-index: 1; }
      .btn.ripple span:after {
        content: '';
        position: absolute;
        z-index: -1;
        top: 50%;
        left: 50%;
        background: rgba(255, 255, 255, 0.5);
        width: 80px;
        height: 80px;
        border-radius: 50%;
        -webkit-transform: translateX(-50%) translateY(-50%) scale(0);
                transform: translateX(-50%) translateY(-50%) scale(0); }
    .btn.ripple:focus:not(:active) span:after {
      -webkit-animation: circleGrow 0.35s linear;
              animation: circleGrow 0.35s linear; }
  .btn.filled {
    border: 0;
    color: #fff;
    line-height: 45px; }
    .btn.filled.primary {
      background-color: #179DFF; }
      .btn.filled.primary:hover {
        background-color: #0394ff; }
    .btn.filled.secondary {
      background-color: #1e3143; }
      .btn.filled.secondary:hover {
        background-color: #182735; }
    .btn.filled.warning {
      background-color: #ca3838; }
      .btn.filled.warning:hover {
        background-color: #bc3232; }
    .btn.filled.text {
      background-color: #0c0c0c; }
      .btn.filled.text:hover {
        background-color: #020202; }
    .btn.filled.text-70 {
      background-color: rgba(12, 12, 12, 0.7); }
      .btn.filled.text-70:hover {
        background-color: rgba(2, 2, 2, 0.7); }
    .btn.filled.text-50 {
      background-color: rgba(12, 12, 12, 0.5); }
      .btn.filled.text-50:hover {
        background-color: rgba(2, 2, 2, 0.5); }
    .btn.filled.white {
      background-color: #fff; }
      .btn.filled.white:hover {
        background-color: whitesmoke; }
    .btn.filled.color-primary {
      color: #179DFF; }
    .btn.filled.color-text {
      color: #0c0c0c; }
  .btn.outline {
    line-height: 46px;
    border: 1px solid transparent;
    box-shadow: 1px 1px 3px 0 rgba(0, 0, 0, 0.08);
    color: #179DFF; }
    .btn.outline.with-icon .icon {
      vertical-align: middle;
      margin-right: 7px; }
    .btn.outline.primary {
      border-color: rgba(23, 157, 255, 0.2);
      background-color: rgba(23, 157, 255, 0.05);
      color: #179DFF; }
      .btn.outline.primary:hover {
        border-color: #0089ed; }
    .btn.outline.secondary {
      border-color: rgba(30, 49, 67, 0.2);
      background-color: rgba(30, 49, 67, 0.05);
      color: #1e3143; }
      .btn.outline.secondary:hover {
        border-color: #111c27; }
    .btn.outline.warning {
      border-color: rgba(202, 56, 56, 0.2);
      background-color: rgba(202, 56, 56, 0.05);
      color: #ca3838; }
      .btn.outline.warning:hover {
        border-color: #ac2e2e; }
    .btn.outline.text {
      border-color: rgba(12, 12, 12, 0.2);
      background-color: rgba(12, 12, 12, 0.05);
      color: #0c0c0c; }
      .btn.outline.text:hover {
        border-color: black; }
    .btn.outline.text-70 {
      border-color: rgba(12, 12, 12, 0.2);
      background-color: rgba(12, 12, 12, 0.05);
      color: rgba(12, 12, 12, 0.7); }
      .btn.outline.text-70:hover {
        border-color: rgba(0, 0, 0, 0.7); }
    .btn.outline.text-50 {
      border-color: rgba(12, 12, 12, 0.2);
      background-color: rgba(12, 12, 12, 0.05);
      color: rgba(12, 12, 12, 0.5); }
      .btn.outline.text-50:hover {
        border-color: rgba(0, 0, 0, 0.5); }
    .btn.outline.white {
      border-color: rgba(255, 255, 255, 0.2);
      background-color: rgba(255, 255, 255, 0.05);
      color: #fff; }
      .btn.outline.white:hover {
        border-color: #ebebeb; }
  .btn.link-btn {
    border: 0;
    line-height: 41px;
    background-color: transparent; }
    .btn.link-btn.with-icon .icon {
      vertical-align: middle;
      margin-right: 7px; }
    .btn.link-btn.primary {
      color: #179DFF; }
      .btn.link-btn.primary:hover {
        color: #0394ff; }
    .btn.link-btn.secondary {
      color: #1e3143; }
      .btn.link-btn.secondary:hover {
        color: #182735; }
    .btn.link-btn.warning {
      color: #ca3838; }
      .btn.link-btn.warning:hover {
        color: #bc3232; }
    .btn.link-btn.text {
      color: #0c0c0c; }
      .btn.link-btn.text:hover {
        color: #020202; }
    .btn.link-btn.text-70 {
      color: rgba(12, 12, 12, 0.7); }
      .btn.link-btn.text-70:hover {
        color: rgba(2, 2, 2, 0.7); }
    .btn.link-btn.text-50 {
      color: rgba(12, 12, 12, 0.5); }
      .btn.link-btn.text-50:hover {
        color: rgba(2, 2, 2, 0.5); }
    .btn.link-btn.white {
      color: #fff; }
      .btn.link-btn.white:hover {
        color: whitesmoke; }
  .btn:hover {
    text-decoration: none; }
    .btn:hover:not(.link):not([disabled]):not(.disabled):not(.link-btn) {
      box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16); }
  .btn[disabled], .btn.disabled {
    border: 0;
    cursor: default;
    line-height: 41px;
    color: rgba(255, 255, 255, 0.6);
    opacity: .7; }
  .btn.small {
    height: 35px;
    font-size: 14px;
    line-height: 35px; }
  .btn.min-110 {
    min-width: 110px; }
  .btn.min-160 {
    min-width: 160px; }
  .btn.min-180 {
    min-width: 180px; }
  .btn.min-220 {
    min-width: 220px; }

.drawer-btn {
  font-size: 28px;
  cursor: pointer; }
  .drawer-btn .icon-close {
    display: block; }

.btn-360 {
  border: 1px solid rgba(0, 0, 0, 0.4);
  padding: 0 7px;
  border-radius: 4px;
  background-color: #fff;
  color: #000;
  height: 28px;
  line-height: 28px;
  font-size: 13px;
  position: absolute;
  right: 5px;
  bottom: 5px;
  z-index: 1; }

.radio-btn input[type="radio"] {
  position: absolute;
  opacity: 0; }
  .radio-btn input[type="radio"]:checked ~ .checkmark:after {
    opacity: 1; }

.radio-btn .checkmark {
  height: 24px;
  width: 24px;
  background-color: #fff;
  border-radius: 50%;
  display: inline-block;
  position: relative;
  border: 2px solid #179DFF; }
  .radio-btn .checkmark:after {
    content: '';
    display: block;
    position: absolute;
    border-radius: 50%;
    height: 14px;
    width: 14px;
    top: 3px;
    left: 3px;
    background-color: #179DFF;
    transition: all 0.25s linear;
    -webkit-transition: all 0.25s linear;
    opacity: 0; }

.radio-btn label {
  font-size: 14px;
  font-weight: 700;
  cursor: pointer; }
  .radio-btn label .icon {
    margin-right: 20px; }

.radio-btn.radio-right label {
  display: flex;
  align-items: center; }

.radio-btn.radio-right .checkmark {
  margin-left: auto; }

.checkbox label {
  cursor: pointer; }

.checkbox input[type="checkbox"] {
  position: absolute;
  opacity: 0; }
  .checkbox input[type="checkbox"]:checked ~ .checkmark {
    background-color: rgba(23, 157, 255, 0.2); }
    .checkbox input[type="checkbox"]:checked ~ .checkmark:after {
      opacity: 1; }

.checkbox .checkmark {
  height: 18px;
  width: 18px;
  background-color: #fff;
  display: inline-block;
  position: relative;
  border: 2px solid #179DFF;
  border-radius: 2px;
  -webkit-transition: all 0.2s linear;
  transition: all 0.2s linear; }
  .checkbox .checkmark:after {
    content: '\e93b';
    font-family: "icomoon";
    display: block;
    position: absolute;
    top: 3px;
    left: 1px;
    font-size: 9px;
    -webkit-transition: all 0.2s linear;
    transition: all 0.2s linear;
    opacity: 0;
    color: #179DFF; }

.switch {
  position: relative;
  display: inline-block;
  width: 34px;
  height: 14px; }
  .switch input {
    opacity: 0;
    width: 0;
    height: 0; }

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(34, 31, 31, 0.45);
  border-radius: 34px;
  -webkit-transition: .4s;
  transition: .4s; }
  .slider:before {
    position: absolute;
    content: "";
    height: 20px;
    width: 20px;
    left: -1px;
    top: -3px;
    background-color: #fff;
    -webkit-transition: .4s;
    transition: .4s;
    border-radius: 50%;
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.25); }

input:checked + .slider {
  background-color: rgba(38, 190, 124, 0.5); }
  input:checked + .slider:before {
    -webkit-transform: translateX(19px);
            transform: translateX(19px);
    background-color: #26BE7C; }

input:focus + .slider {
  box-shadow: 0 0 1px rgba(38, 190, 124, 0.5); }

input:disabled + .slider {
  opacity: .4; }

.font-xs {
  font-size: 12px; }

.font-xsl {
  font-size: 13px; }

.font-sm {
  font-size: 14px; }

.font-normal {
  font-size: 16px; }

.font-md {
  font-size: 18px; }

.font-lg {
  font-size: 24px; }

.font-xl {
  font-size: 32px; }

/* Heading Text Sizes */
h6, .h6 {
  font-size: 12px; }

h5, .h5 {
  font-size: 14px; }

h4, .h4 {
  font-size: 16px; }

h3, .h3 {
  font-size: 18px; }

h2, .h2 {
  font-size: 24px; }

h1, .h1 {
  font-size: 32px; }

/* Text Colors */
.color-primary {
  color: #179DFF; }

.color-secondary {
  color: #1e3143; }

.color-warning {
  color: #ca3838; }

.color-text {
  color: #0c0c0c; }

.color-text-70 {
  color: rgba(12, 12, 12, 0.7); }

.color-text-50 {
  color: rgba(12, 12, 12, 0.5); }

.color-white {
  color: #fff; }

.lh-24 {
  line-height: 24px; }

.italic {
  font-style: italic; }

.fw-400 {
  font-weight: 400; }

.fw-500 {
  font-weight: 500; }

.fw-600 {
  font-weight: 600; }

.fw-700 {
  font-weight: 700; }

.mt-0 {
  margin-top: 0px; }

.mr-0 {
  margin-right: 0px; }

.mb-0 {
  margin-bottom: 0px; }

.ml-0 {
  margin-left: 0px; }

.my-0 {
  margin-top: 0px;
  margin-bottom: 0px; }

.mx-0 {
  margin-left: 0px;
  margin-right: 0px; }

.m-0 {
  margin: 0px; }

.pt-0 {
  padding-top: 0px; }

.pr-0 {
  padding-right: 0px; }

.pb-0 {
  padding-bottom: 0px; }

.pl-0 {
  padding-left: 0px; }

.py-0 {
  padding-top: 0px;
  padding-bottom: 0px; }

.px-0 {
  padding-left: 0px;
  padding-right: 0px; }

.p-0 {
  padding: 0px; }

.mt-5 {
  margin-top: 5px; }

.mr-5 {
  margin-right: 5px; }

.mb-5 {
  margin-bottom: 5px; }

.ml-5 {
  margin-left: 5px; }

.my-5 {
  margin-top: 5px;
  margin-bottom: 5px; }

.mx-5 {
  margin-left: 5px;
  margin-right: 5px; }

.m-5 {
  margin: 5px; }

.pt-5 {
  padding-top: 5px; }

.pr-5 {
  padding-right: 5px; }

.pb-5 {
  padding-bottom: 5px; }

.pl-5 {
  padding-left: 5px; }

.py-5 {
  padding-top: 5px;
  padding-bottom: 5px; }

.px-5 {
  padding-left: 5px;
  padding-right: 5px; }

.p-5 {
  padding: 5px; }

.mt-10 {
  margin-top: 10px; }

.mr-10 {
  margin-right: 10px; }

.mb-10 {
  margin-bottom: 10px; }

.ml-10 {
  margin-left: 10px; }

.my-10 {
  margin-top: 10px;
  margin-bottom: 10px; }

.mx-10 {
  margin-left: 10px;
  margin-right: 10px; }

.m-10 {
  margin: 10px; }

.pt-10 {
  padding-top: 10px; }

.pr-10 {
  padding-right: 10px; }

.pb-10 {
  padding-bottom: 10px; }

.pl-10 {
  padding-left: 10px; }

.py-10 {
  padding-top: 10px;
  padding-bottom: 10px; }

.px-10 {
  padding-left: 10px;
  padding-right: 10px; }

.p-10 {
  padding: 10px; }

.mt-15 {
  margin-top: 15px; }

.mr-15 {
  margin-right: 15px; }

.mb-15 {
  margin-bottom: 15px; }

.ml-15 {
  margin-left: 15px; }

.my-15 {
  margin-top: 15px;
  margin-bottom: 15px; }

.mx-15 {
  margin-left: 15px;
  margin-right: 15px; }

.m-15 {
  margin: 15px; }

.pt-15 {
  padding-top: 15px; }

.pr-15 {
  padding-right: 15px; }

.pb-15 {
  padding-bottom: 15px; }

.pl-15 {
  padding-left: 15px; }

.py-15 {
  padding-top: 15px;
  padding-bottom: 15px; }

.px-15 {
  padding-left: 15px;
  padding-right: 15px; }

.p-15 {
  padding: 15px; }

.mt-20 {
  margin-top: 20px; }

.mr-20 {
  margin-right: 20px; }

.mb-20 {
  margin-bottom: 20px; }

.ml-20 {
  margin-left: 20px; }

.my-20 {
  margin-top: 20px;
  margin-bottom: 20px; }

.mx-20 {
  margin-left: 20px;
  margin-right: 20px; }

.m-20 {
  margin: 20px; }

.pt-20 {
  padding-top: 20px; }

.pr-20 {
  padding-right: 20px; }

.pb-20 {
  padding-bottom: 20px; }

.pl-20 {
  padding-left: 20px; }

.py-20 {
  padding-top: 20px;
  padding-bottom: 20px; }

.px-20 {
  padding-left: 20px;
  padding-right: 20px; }

.p-20 {
  padding: 20px; }

.mt-25 {
  margin-top: 25px; }

.mr-25 {
  margin-right: 25px; }

.mb-25 {
  margin-bottom: 25px; }

.ml-25 {
  margin-left: 25px; }

.my-25 {
  margin-top: 25px;
  margin-bottom: 25px; }

.mx-25 {
  margin-left: 25px;
  margin-right: 25px; }

.m-25 {
  margin: 25px; }

.pt-25 {
  padding-top: 25px; }

.pr-25 {
  padding-right: 25px; }

.pb-25 {
  padding-bottom: 25px; }

.pl-25 {
  padding-left: 25px; }

.py-25 {
  padding-top: 25px;
  padding-bottom: 25px; }

.px-25 {
  padding-left: 25px;
  padding-right: 25px; }

.p-25 {
  padding: 25px; }

.mt-30 {
  margin-top: 30px; }

.mr-30 {
  margin-right: 30px; }

.mb-30 {
  margin-bottom: 30px; }

.ml-30 {
  margin-left: 30px; }

.my-30 {
  margin-top: 30px;
  margin-bottom: 30px; }

.mx-30 {
  margin-left: 30px;
  margin-right: 30px; }

.m-30 {
  margin: 30px; }

.pt-30 {
  padding-top: 30px; }

.pr-30 {
  padding-right: 30px; }

.pb-30 {
  padding-bottom: 30px; }

.pl-30 {
  padding-left: 30px; }

.py-30 {
  padding-top: 30px;
  padding-bottom: 30px; }

.px-30 {
  padding-left: 30px;
  padding-right: 30px; }

.p-30 {
  padding: 30px; }

.mt-35 {
  margin-top: 35px; }

.mr-35 {
  margin-right: 35px; }

.mb-35 {
  margin-bottom: 35px; }

.ml-35 {
  margin-left: 35px; }

.my-35 {
  margin-top: 35px;
  margin-bottom: 35px; }

.mx-35 {
  margin-left: 35px;
  margin-right: 35px; }

.m-35 {
  margin: 35px; }

.pt-35 {
  padding-top: 35px; }

.pr-35 {
  padding-right: 35px; }

.pb-35 {
  padding-bottom: 35px; }

.pl-35 {
  padding-left: 35px; }

.py-35 {
  padding-top: 35px;
  padding-bottom: 35px; }

.px-35 {
  padding-left: 35px;
  padding-right: 35px; }

.p-35 {
  padding: 35px; }

.mt-40 {
  margin-top: 40px; }

.mr-40 {
  margin-right: 40px; }

.mb-40 {
  margin-bottom: 40px; }

.ml-40 {
  margin-left: 40px; }

.my-40 {
  margin-top: 40px;
  margin-bottom: 40px; }

.mx-40 {
  margin-left: 40px;
  margin-right: 40px; }

.m-40 {
  margin: 40px; }

.pt-40 {
  padding-top: 40px; }

.pr-40 {
  padding-right: 40px; }

.pb-40 {
  padding-bottom: 40px; }

.pl-40 {
  padding-left: 40px; }

.py-40 {
  padding-top: 40px;
  padding-bottom: 40px; }

.px-40 {
  padding-left: 40px;
  padding-right: 40px; }

.p-40 {
  padding: 40px; }

.mt-45 {
  margin-top: 45px; }

.mr-45 {
  margin-right: 45px; }

.mb-45 {
  margin-bottom: 45px; }

.ml-45 {
  margin-left: 45px; }

.my-45 {
  margin-top: 45px;
  margin-bottom: 45px; }

.mx-45 {
  margin-left: 45px;
  margin-right: 45px; }

.m-45 {
  margin: 45px; }

.pt-45 {
  padding-top: 45px; }

.pr-45 {
  padding-right: 45px; }

.pb-45 {
  padding-bottom: 45px; }

.pl-45 {
  padding-left: 45px; }

.py-45 {
  padding-top: 45px;
  padding-bottom: 45px; }

.px-45 {
  padding-left: 45px;
  padding-right: 45px; }

.p-45 {
  padding: 45px; }

.mt-50 {
  margin-top: 50px; }

.mr-50 {
  margin-right: 50px; }

.mb-50 {
  margin-bottom: 50px; }

.ml-50 {
  margin-left: 50px; }

.my-50 {
  margin-top: 50px;
  margin-bottom: 50px; }

.mx-50 {
  margin-left: 50px;
  margin-right: 50px; }

.m-50 {
  margin: 50px; }

.pt-50 {
  padding-top: 50px; }

.pr-50 {
  padding-right: 50px; }

.pb-50 {
  padding-bottom: 50px; }

.pl-50 {
  padding-left: 50px; }

.py-50 {
  padding-top: 50px;
  padding-bottom: 50px; }

.px-50 {
  padding-left: 50px;
  padding-right: 50px; }

.p-50 {
  padding: 50px; }

.mt-55 {
  margin-top: 55px; }

.mr-55 {
  margin-right: 55px; }

.mb-55 {
  margin-bottom: 55px; }

.ml-55 {
  margin-left: 55px; }

.my-55 {
  margin-top: 55px;
  margin-bottom: 55px; }

.mx-55 {
  margin-left: 55px;
  margin-right: 55px; }

.m-55 {
  margin: 55px; }

.pt-55 {
  padding-top: 55px; }

.pr-55 {
  padding-right: 55px; }

.pb-55 {
  padding-bottom: 55px; }

.pl-55 {
  padding-left: 55px; }

.py-55 {
  padding-top: 55px;
  padding-bottom: 55px; }

.px-55 {
  padding-left: 55px;
  padding-right: 55px; }

.p-55 {
  padding: 55px; }

.mt-60 {
  margin-top: 60px; }

.mr-60 {
  margin-right: 60px; }

.mb-60 {
  margin-bottom: 60px; }

.ml-60 {
  margin-left: 60px; }

.my-60 {
  margin-top: 60px;
  margin-bottom: 60px; }

.mx-60 {
  margin-left: 60px;
  margin-right: 60px; }

.m-60 {
  margin: 60px; }

.pt-60 {
  padding-top: 60px; }

.pr-60 {
  padding-right: 60px; }

.pb-60 {
  padding-bottom: 60px; }

.pl-60 {
  padding-left: 60px; }

.py-60 {
  padding-top: 60px;
  padding-bottom: 60px; }

.px-60 {
  padding-left: 60px;
  padding-right: 60px; }

.p-60 {
  padding: 60px; }

.mt-65 {
  margin-top: 65px; }

.mr-65 {
  margin-right: 65px; }

.mb-65 {
  margin-bottom: 65px; }

.ml-65 {
  margin-left: 65px; }

.my-65 {
  margin-top: 65px;
  margin-bottom: 65px; }

.mx-65 {
  margin-left: 65px;
  margin-right: 65px; }

.m-65 {
  margin: 65px; }

.pt-65 {
  padding-top: 65px; }

.pr-65 {
  padding-right: 65px; }

.pb-65 {
  padding-bottom: 65px; }

.pl-65 {
  padding-left: 65px; }

.py-65 {
  padding-top: 65px;
  padding-bottom: 65px; }

.px-65 {
  padding-left: 65px;
  padding-right: 65px; }

.p-65 {
  padding: 65px; }

.mt-70 {
  margin-top: 70px; }

.mr-70 {
  margin-right: 70px; }

.mb-70 {
  margin-bottom: 70px; }

.ml-70 {
  margin-left: 70px; }

.my-70 {
  margin-top: 70px;
  margin-bottom: 70px; }

.mx-70 {
  margin-left: 70px;
  margin-right: 70px; }

.m-70 {
  margin: 70px; }

.pt-70 {
  padding-top: 70px; }

.pr-70 {
  padding-right: 70px; }

.pb-70 {
  padding-bottom: 70px; }

.pl-70 {
  padding-left: 70px; }

.py-70 {
  padding-top: 70px;
  padding-bottom: 70px; }

.px-70 {
  padding-left: 70px;
  padding-right: 70px; }

.p-70 {
  padding: 70px; }

.mt-75 {
  margin-top: 75px; }

.mr-75 {
  margin-right: 75px; }

.mb-75 {
  margin-bottom: 75px; }

.ml-75 {
  margin-left: 75px; }

.my-75 {
  margin-top: 75px;
  margin-bottom: 75px; }

.mx-75 {
  margin-left: 75px;
  margin-right: 75px; }

.m-75 {
  margin: 75px; }

.pt-75 {
  padding-top: 75px; }

.pr-75 {
  padding-right: 75px; }

.pb-75 {
  padding-bottom: 75px; }

.pl-75 {
  padding-left: 75px; }

.py-75 {
  padding-top: 75px;
  padding-bottom: 75px; }

.px-75 {
  padding-left: 75px;
  padding-right: 75px; }

.p-75 {
  padding: 75px; }

.mt-80 {
  margin-top: 80px; }

.mr-80 {
  margin-right: 80px; }

.mb-80 {
  margin-bottom: 80px; }

.ml-80 {
  margin-left: 80px; }

.my-80 {
  margin-top: 80px;
  margin-bottom: 80px; }

.mx-80 {
  margin-left: 80px;
  margin-right: 80px; }

.m-80 {
  margin: 80px; }

.pt-80 {
  padding-top: 80px; }

.pr-80 {
  padding-right: 80px; }

.pb-80 {
  padding-bottom: 80px; }

.pl-80 {
  padding-left: 80px; }

.py-80 {
  padding-top: 80px;
  padding-bottom: 80px; }

.px-80 {
  padding-left: 80px;
  padding-right: 80px; }

.p-80 {
  padding: 80px; }

.mt-85 {
  margin-top: 85px; }

.mr-85 {
  margin-right: 85px; }

.mb-85 {
  margin-bottom: 85px; }

.ml-85 {
  margin-left: 85px; }

.my-85 {
  margin-top: 85px;
  margin-bottom: 85px; }

.mx-85 {
  margin-left: 85px;
  margin-right: 85px; }

.m-85 {
  margin: 85px; }

.pt-85 {
  padding-top: 85px; }

.pr-85 {
  padding-right: 85px; }

.pb-85 {
  padding-bottom: 85px; }

.pl-85 {
  padding-left: 85px; }

.py-85 {
  padding-top: 85px;
  padding-bottom: 85px; }

.px-85 {
  padding-left: 85px;
  padding-right: 85px; }

.p-85 {
  padding: 85px; }

.mt-90 {
  margin-top: 90px; }

.mr-90 {
  margin-right: 90px; }

.mb-90 {
  margin-bottom: 90px; }

.ml-90 {
  margin-left: 90px; }

.my-90 {
  margin-top: 90px;
  margin-bottom: 90px; }

.mx-90 {
  margin-left: 90px;
  margin-right: 90px; }

.m-90 {
  margin: 90px; }

.pt-90 {
  padding-top: 90px; }

.pr-90 {
  padding-right: 90px; }

.pb-90 {
  padding-bottom: 90px; }

.pl-90 {
  padding-left: 90px; }

.py-90 {
  padding-top: 90px;
  padding-bottom: 90px; }

.px-90 {
  padding-left: 90px;
  padding-right: 90px; }

.p-90 {
  padding: 90px; }

.mt-95 {
  margin-top: 95px; }

.mr-95 {
  margin-right: 95px; }

.mb-95 {
  margin-bottom: 95px; }

.ml-95 {
  margin-left: 95px; }

.my-95 {
  margin-top: 95px;
  margin-bottom: 95px; }

.mx-95 {
  margin-left: 95px;
  margin-right: 95px; }

.m-95 {
  margin: 95px; }

.pt-95 {
  padding-top: 95px; }

.pr-95 {
  padding-right: 95px; }

.pb-95 {
  padding-bottom: 95px; }

.pl-95 {
  padding-left: 95px; }

.py-95 {
  padding-top: 95px;
  padding-bottom: 95px; }

.px-95 {
  padding-left: 95px;
  padding-right: 95px; }

.p-95 {
  padding: 95px; }

.mt-100 {
  margin-top: 100px; }

.mr-100 {
  margin-right: 100px; }

.mb-100 {
  margin-bottom: 100px; }

.ml-100 {
  margin-left: 100px; }

.my-100 {
  margin-top: 100px;
  margin-bottom: 100px; }

.mx-100 {
  margin-left: 100px;
  margin-right: 100px; }

.m-100 {
  margin: 100px; }

.pt-100 {
  padding-top: 100px; }

.pr-100 {
  padding-right: 100px; }

.pb-100 {
  padding-bottom: 100px; }

.pl-100 {
  padding-left: 100px; }

.py-100 {
  padding-top: 100px;
  padding-bottom: 100px; }

.px-100 {
  padding-left: 100px;
  padding-right: 100px; }

.p-100 {
  padding: 100px; }

h1 {
  font-family: "Montserrat", sans-serif; }

h2 {
  font-family: "Montserrat", sans-serif; }

h3 {
  font-family: "Montserrat", sans-serif; }

h4 {
  font-family: "Montserrat", sans-serif; }

h5 {
  font-family: "Montserrat", sans-serif; }

h6 {
  font-family: "Montserrat", sans-serif; }

@media (min-width: 360px) {
  .text-xxs-left {
    text-align: left; }
  .text-xxs-right {
    text-align: right; }
  .text-xxs-center {
    text-align: center; }
  .text-xxs-justify {
    text-align: justify; } }

@media (min-width: 500px) {
  .text-xs-left {
    text-align: left; }
  .text-xs-right {
    text-align: right; }
  .text-xs-center {
    text-align: center; }
  .text-xs-justify {
    text-align: justify; } }

@media (min-width: 768px) {
  .text-sm-left {
    text-align: left; }
  .text-sm-right {
    text-align: right; }
  .text-sm-center {
    text-align: center; }
  .text-sm-justify {
    text-align: justify; } }

@media (min-width: 1024px) {
  .text-md-left {
    text-align: left; }
  .text-md-right {
    text-align: right; }
  .text-md-center {
    text-align: center; }
  .text-md-justify {
    text-align: justify; } }

@media (min-width: 1200px) {
  .text-lg-left {
    text-align: left; }
  .text-lg-right {
    text-align: right; }
  .text-lg-center {
    text-align: center; }
  .text-lg-justify {
    text-align: justify; } }

.text-center {
  text-align: center; }

.text-right {
  text-align: right; }

.text-left {
  text-align: left; }

.text-lowercase {
  text-transform: lowercase; }

.text-uppercase {
  text-transform: uppercase; }

.text-capitalize {
  text-transform: capitalize; }

.textfield {
  position: relative;
  margin-bottom: 28px; }
  .textfield.mb-15 {
    margin-bottom: 15px; }
  .textfield.no-margin {
    margin-bottom: 0; }
  .textfield .label {
    font-size: 14px;
    font-weight: 600;
    padding-bottom: 10px;
    display: block;
    color: rgba(12, 12, 12, 0.7); }
  .textfield input[type='text'], .textfield input[type='email'], .textfield input[type='password'] {
    border-radius: 4px;
    font-size: 16px;
    border: 1px solid rgba(12, 12, 12, 0.1);
    height: 44px;
    line-height: 44px;
    padding: 12px 20px;
    -webkit-transition: all .3s;
    transition: all .3s;
    width: 100%;
    background-color: transparent; }
    .textfield input[type='text']:focus, .textfield input[type='email']:focus, .textfield input[type='password']:focus {
      border: 1px solid #0c0c0c;
      opacity: 1; }
      .textfield input[type='text']:focus ~ .icon, .textfield input[type='email']:focus ~ .icon, .textfield input[type='password']:focus ~ .icon {
        opacity: 1; }
  .textfield.with-btn input {
    padding-right: 60px; }
    .textfield.with-btn input:focus ~ button {
      border-color: #0c0c0c; }
  .textfield.with-btn button {
    position: absolute;
    right: 0;
    height: 100%;
    width: 43px;
    border-radius: 0 4px 4px 0;
    background-color: #26BE7C;
    color: #fff;
    border: 1px solid #209261;
    -webkit-transition: all .3s;
    transition: all .3s; }
  .textfield.with-icon input[type='text'], .textfield.with-icon input[type='email'], .textfield.with-icon input[type='password'] {
    padding-left: 50px;
    padding-right: 20px; }
  .textfield.with-icon .icon {
    position: absolute;
    left: 15px;
    bottom: 10px;
    font-size: 24px;
    opacity: .5;
    -webkit-transition: all .3s;
    transition: all .3s; }
  .textfield.with-right-icon input[type='text'], .textfield.with-right-icon input[type='email'], .textfield.with-right-icon input[type='password'] {
    padding-right: 50px;
    padding-left: 20px; }
  .textfield.with-right-icon .icon {
    position: absolute;
    right: 15px;
    top: 10px;
    font-size: 24px;
    opacity: .5;
    -webkit-transition: all .3s;
    transition: all .3s; }
  .textfield.with-dropdown input[type='text'], .textfield.with-dropdown input[type='email'], .textfield.with-dropdown input[type='password'] {
    padding-right: 80px; }
  .textfield.with-dropdown .icon-arrow-dropdown {
    cursor: pointer;
    position: absolute;
    right: 44px;
    top: 50%;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
    height: 44px;
    line-height: 44px;
    width: 40px;
    text-align: center; }
  .textfield.error-field input[type='text'], .textfield.error-field input[type='email'], .textfield.error-field input[type='password'] {
    border-color: #ca3838;
    caret-color: #ca3838; }
  .textfield.error-field .error-text {
    font-size: 11px;
    font-style: italic;
    color: #ca3838;
    font-weight: 700;
    position: absolute;
    top: calc(100% + 4px); }
  .textfield .css-yk16xz-control {
    min-height: 44px;
    line-height: 44px; }
  .textfield .css-1hwfws3 {
    padding: 0 8px; }
  .textfield .css-w8afj7-Input {
    margin: 0;
    padding: 0; }
  .textfield .inner__control {
    border-color: rgba(12, 12, 12, 0.1);
    box-shadow: none; }
    .textfield .inner__control:hover {
      border-color: rgba(12, 12, 12, 0.1); }
  .textfield .inner__control--is-focused {
    border-color: #0c0c0c; }
    .textfield .inner__control--is-focused:hover {
      border-color: #0c0c0c; }

.select-style .inner__control {
  background-color: transparent;
  min-height: 46px;
  border-color: rgba(255, 255, 255, 0.4); }
  .select-style .inner__control:hover {
    border-color: rgba(255, 255, 255, 0.7); }

.select-style .css-1uccc91-singleValue {
  color: #fff; }

.select-style .inner__value-container {
  padding: 9px 8px; }

.select-style .inner__placeholder {
  color: #fff; }

.select-style .inner__input input {
  color: #fff !important; }

.select-style .inner__indicator-separator {
  display: none; }

.select-style .inner__indicator {
  color: rgba(255, 255, 255, 0.4); }
  .select-style .inner__indicator:hover {
    color: rgba(255, 255, 255, 0.7); }

.select-style .inner__menu {
  margin-bottom: 100px; }
  .select-style .inner__menu .inner__menu-list {
    padding-top: 15px;
    padding-bottom: 15px; }
  .select-style .inner__menu .inner__option {
    color: #0c0c0c;
    font-weight: 700;
    padding: 8px 40px;
    font-size: 16px; }
    .select-style .inner__menu .inner__option:active {
      background-color: #179DFF; }
  .select-style .inner__menu .inner__option inner__option--is-focused,
  .select-style .inner__menu .inner__option--is-selected,
  .select-style .inner__menu .css-9gakcf-option {
    background-color: #179DFF;
    color: #fff; }

.stepper .stepper-header {
  padding: 45px 80px 20px; }

.stepper .stepper-header-top {
  display: flex; }
  .stepper .stepper-header-top li {
    width: 100%;
    position: relative;
    text-align: right; }
    .stepper .stepper-header-top li:first-child {
      width: 40px; }
    .stepper .stepper-header-top li span {
      display: inline-block;
      height: 40px;
      width: 40px;
      line-height: 40px;
      text-align: center;
      font-weight: 700;
      font-size: 18px;
      color: #fff;
      background-color: rgba(12, 12, 12, 0.1);
      border-radius: 50%;
      position: relative;
      z-index: 1; }
      .stepper .stepper-header-top li span.icon {
        font-size: 14px; }
    .stepper .stepper-header-top li:not(:first-child):before {
      content: "";
      display: block;
      position: absolute;
      top: 50%;
      left: 0;
      -webkit-transform: translateY(-50%);
              transform: translateY(-50%);
      height: 1px;
      width: calc(100% - 40px);
      background-color: rgba(12, 12, 12, 0.1); }
    .stepper .stepper-header-top li.active span {
      background-color: #179DFF; }
    .stepper .stepper-header-top li.active:before {
      background-color: #179DFF; }

.stepper .stepper-header-bottom {
  text-align: center;
  padding-top: 35px; }
  .stepper .stepper-header-bottom h4 {
    padding-bottom: 15px; }

.list-item li {
  -webkit-transition: all .2s;
  transition: all .2s; }
  .list-item li label {
    padding: 25px 20px;
    display: block; }
  .list-item li:not(:last-child) {
    border-bottom: 1px solid rgba(12, 12, 12, 0.1); }
  .list-item li.active {
    background-color: #179DFF;
    color: #fff; }
    .list-item li.active .checkmark {
      background-color: #179DFF;
      border: 2px solid #fff; }
      .list-item li.active .checkmark:after {
        background-color: #fff; }

.dropdown {
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.15); }
  .dropdown .dropdown-btn {
    padding: 15px 40px 15px 20px;
    position: relative;
    cursor: pointer; }
  .dropdown .dropdown-arrow {
    position: absolute;
    top: 50%;
    right: 25px;
    -webkit-transform: translateY(-50%) rotate(90deg);
            transform: translateY(-50%) rotate(90deg);
    -webkit-transition: all .3s;
    transition: all .3s; }
  .dropdown .dropdown-content {
    padding: 20px;
    display: none;
    visibility: hidden;
    opacity: 0;
    -webkit-transition: all .3s;
    transition: all .3s;
    border-top: 1px solid rgba(12, 12, 12, 0.1); }
  .dropdown.open .dropdown-content {
    display: block;
    visibility: visible;
    opacity: 1; }
  .dropdown.open .dropdown-arrow {
    -webkit-transform: translateY(-50%) rotate(-90deg);
            transform: translateY(-50%) rotate(-90deg); }

.tag-group {
  display: flex;
  flex-wrap: wrap; }
  .tag-group .tag-item {
    padding: 0 12px;
    line-height: 25px;
    height: 25px;
    background-color: #26BE7C;
    margin-right: 8px;
    margin-bottom: 8px;
    border-radius: 3px;
    color: rgba(255, 255, 255, 0.9);
    font-size: 13px;
    display: flex;
    align-items: center; }
    .tag-group .tag-item .tag-text {
      max-width: 101px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis; }
    .tag-group .tag-item.with-close {
      padding: 0 6px 0 12px; }
      .tag-group .tag-item.with-close .icon {
        margin-left: 7px;
        font-size: 18px;
        cursor: pointer;
        color: rgba(255, 255, 255, 0.6);
        -webkit-transition: all .3s;
        transition: all .3s; }
        .tag-group .tag-item.with-close .icon:hover {
          color: rgba(255, 255, 255, 0.8); }

.tag-dropdown {
  position: relative; }
  .tag-dropdown .dropdown-content {
    width: calc(100% - 43px);
    height: 225px;
    position: absolute;
    top: 100%;
    padding: 20px 20px 0;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
    background-color: #F8F8F8;
    margin-bottom: 100px; }
  .tag-dropdown .tag-item {
    cursor: pointer;
    border: 1px solid #26BE7C;
    -webkit-transition: all .3s;
    transition: all .3s; }
    .tag-dropdown .tag-item.selected {
      border: 1px solid #0c0c0c;
      background-color: #23b174; }

.table-style table {
  width: 100%; }
  .table-style table th, .table-style table td {
    vertical-align: middle;
    padding-left: 10px;
    padding-right: 10px; }
    .table-style table th.text-center, .table-style table td.text-center {
      text-align: center; }
  .table-style table th {
    text-align: left;
    font-size: 14px;
    font-weight: 700;
    padding-bottom: 11px; }
  .table-style table td {
    font-size: 14px;
    font-weight: 400;
    padding-top: 7px;
    padding-bottom: 7px; }
  .table-style table tbody tr {
    border-bottom: 1px solid rgba(12, 12, 12, 0.1); }

.table-style.with-action table th:last-child, .table-style.with-action table td:last-child {
  text-align: center;
  width: 120px; }

.table-style.user-table table td:nth-child(1) {
  width: 120px; }
  .table-style.user-table table td:nth-child(1) > div {
    width: 160px; }

.table-style.user-table table td:nth-child(2) {
  width: 28%; }
  .table-style.user-table table td:nth-child(2) .text-wrapper {
    width: 220px; }

.table-style.user-table table td:nth-child(3) {
  width: 105px; }

.table-style.user-table table td:nth-child(4) {
  width: 15%; }

.table-style .scrollarea-content {
  min-width: 830px;
  padding-bottom: 9px; }

.table-style .scrollarea .scrollbar-container {
  z-index: 1;
  pointer-events: none; }

.styles_modal__gNwvD {
  padding: 0px;
  border-radius: 4px;
  max-width: 800px; }
  .styles_modal__gNwvD img {
    width: 100%;
    display: block; }

.styles_closeButton__20ID4 {
  top: -43px;
  right: -37px;
  cursor: pointer; }
  .styles_closeButton__20ID4 svg {
    fill: rgba(255, 255, 255, 0.7);
    -webkit-transition: all .3s;
    transition: all .3s; }
    .styles_closeButton__20ID4 svg:hover {
      fill: white; }

.modal.modal-sm .styles_modal__gNwvD {
  max-width: 470px;
  width: 100%;
  padding: 40px; }

.modal.modal-md .styles_modal__gNwvD {
  max-width: 550px;
  width: 100%;
  padding: 40px; }

.modal .styles_closeButton__20ID4 {
  display: none; }

:not(.modal) > .styles_overlay__CLSq- {
  padding: 50px; }

.download-btn {
  position: absolute;
  bottom: -50px;
  right: 0; }
  .download-btn .icon:before, .download-btn .icon [class^="path"]:before {
    -webkit-transition: all .3s;
    transition: all .3s; }
  .download-btn:hover {
    color: #179DFF; }
    .download-btn:hover .icon:before, .download-btn:hover .icon [class^="path"]:before {
      color: #179DFF; }

.tab-style {
  border: 1px solid #26BE7C;
  border-radius: 4px;
  display: flex; }
  .tab-style.folder-mobile li .icon {
    display: none; }
  .tab-style li {
    width: 34%;
    text-align: center;
    padding: 10px;
    font-family: "Montserrat", sans-serif;
    font-size: 16px;
    font-weight: 500;
    color: rgba(12, 12, 12, 0.5);
    -webkit-transition: all .3s;
    transition: all .3s;
    cursor: pointer; }
    .tab-style li:not(:last-child) {
      border-right: 1px solid #26BE7C; }
    .tab-style li.active {
      color: #fff;
      background-color: #26BE7C; }

.box {
  background-color: #fff;
  border: 1px solid rgba(12, 12, 12, 0.1); }

.folder-group {
  display: grid;
  grid-gap: 15px;
  grid-template-columns: repeat(3, calc(33.333% - 10px));
  grid-auto-rows: 42px; }

.folder {
  border: 1px solid rgba(23, 157, 255, 0.7);
  background-color: rgba(23, 157, 255, 0.04);
  border-radius: 4px;
  display: flex;
  align-items: center;
  padding: 0 20px;
  cursor: pointer; }
  .folder .icon {
    margin-right: 30px; }
  .folder .folder-name-wrapper {
    display: block;
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis; }
  .folder .folder-name {
    font-size: 16px;
    max-width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis; }

.img-card-list {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap; }
  .img-card-list .img-card {
    width: 48%;
    padding-bottom: 20px;
    margin-bottom: 25px;
    border-bottom: 1px solid rgba(12, 12, 12, 0.1); }

.img-card-top {
  display: flex;
  position: relative; }
  .img-card-top .img {
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    width: 120px;
    height: 120px;
    border-radius: 4px;
    position: relative; }
    .img-card-top .img .img-meta {
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      background-color: rgba(0, 0, 0, 0.6);
      padding: 5px;
      text-align: right;
      color: #fff;
      font-size: 12px; }
  .img-card-top .actions {
    position: absolute;
    top: 0;
    right: 0; }

.img-card-bottom {
  padding-right: 65px;
  position: relative; }
  .img-card-bottom button {
    position: absolute;
    top: 0;
    right: 20px;
    border: 1px solid #26BE7C;
    border-radius: 2px;
    padding: 0 12px;
    line-height: 25px;
    height: 25px; }
    .img-card-bottom button span {
      font-size: 20px;
      color: #26BE7C;
      display: block; }

.empty-page {
  height: calc(100vh - 161px);
  display: flex;
  align-items: center;
  justify-content: center; }

.empty-page-2 {
  height: calc(100vh - 243px);
  display: flex;
  align-items: center;
  justify-content: center; }

.empty-page-3 {
  height: calc(100vh - 70px);
  display: flex;
  align-items: center;
  justify-content: center; }

.empty-img {
  width: 120px; }
  .empty-img.icon {
    font-size: 120px; }

.empty-tag {
  width: 120px; }
  .empty-tag.icon {
    font-size: 120px; }

.spinner-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 11; }
  .spinner-wrapper svg {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translateX(-50%) translateY(-50%);
            transform: translateX(-50%) translateY(-50%); }

/*datepicker*/
.react-datepicker {
  border: 1px solid rgba(12, 12, 12, 0.1);
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.07);
  font-family: "Lato", sans-serif;
  padding: 20px 50px; }

.react-datepicker-wrapper,
.react-datepicker__input-container,
.react-datepicker-ignore-onclickoutside {
  width: 100%; }

.align-right .react-datepicker-popper {
  left: inherit !important;
  right: 0; }

.react-datepicker__triangle {
  display: none; }

.react-datepicker__header {
  background-color: #fff;
  border-bottom: none; }

.react-datepicker__day-name {
  color: rgba(12, 12, 12, 0.5); }

.react-datepicker__current-month {
  padding-bottom: 25px; }

.react-datepicker__day-name,
.react-datepicker__day,
.react-datepicker__time-name {
  width: 36px;
  height: 36px;
  line-height: 36px;
  font-size: 12px; }

.react-datepicker__day--selected,
.react-datepicker__day--in-selecting-range,
.react-datepicker__day--in-range,
.react-datepicker__month-text--selected,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__month-text--in-range {
  background-color: #179DFF;
  border-radius: 50%;
  -webkit-transition: background-color .2s;
  transition: background-color .2s; }
  .react-datepicker__day--selected:hover,
  .react-datepicker__day--in-selecting-range:hover,
  .react-datepicker__day--in-range:hover,
  .react-datepicker__month-text--selected:hover,
  .react-datepicker__month-text--in-selecting-range:hover,
  .react-datepicker__month-text--in-range:hover {
    background-color: #0083e3; }

.react-datepicker__day:hover,
.react-datepicker__month-text:hover {
  border-radius: 50%; }

.react-datepicker__day--keyboard-selected,
.react-datepicker__month-text--keyboard-selected {
  background-color: #179DFF;
  border-radius: 50%;
  background-color: #0083e3; }

.react-datepicker__navigation {
  border: none;
  overflow: visible;
  top: 25px; }
  .react-datepicker__navigation:after {
    font-family: "icomoon";
    position: absolute;
    top: 0;
    width: 24px;
    height: 24px;
    font-size: 24px;
    color: #179DFF;
    text-indent: 0; }
  .react-datepicker__navigation.react-datepicker__navigation--previous {
    left: 65px; }
    .react-datepicker__navigation.react-datepicker__navigation--previous:after {
      content: "\e922";
      left: 0; }
  .react-datepicker__navigation.react-datepicker__navigation--next {
    right: 65px; }
    .react-datepicker__navigation.react-datepicker__navigation--next:after {
      content: "\e91f";
      right: 0; }

/*pagination*/
.rc-pagination > li {
  border-radius: 4px;
  color: #179DFF; }

.rc-pagination-prev a:after {
  content: "\e922";
  font-family: "icomoon"; }

.rc-pagination-next a:after {
  content: "\e91f";
  font-family: "icomoon"; }

.my-collapsible__content {
  overflow: hidden; }

/*toast*/
.Toastify__toast {
  padding: 0;
  border-radius: 4px;
  min-height: 59px;
  max-width: 410px;
  box-shadow: 7px 7px 15px rgba(0, 0, 0, 0.15);
  margin: 10px 0; }

.Toastify__toast-body {
  margin: 0; }

.Toastify__progress-bar {
  opacity: 0;
  visibility: hidden; }

.Toastify__close-button--default {
  padding: 7px 7px 0 0; }

.toast-style {
  display: flex;
  height: 100%; }
  .toast-style.error .left-side {
    background-color: #ca3838; }
  .toast-style.success .left-side {
    background-color: #26BE7C; }
  .toast-style.info .left-side {
    background-color: #179DFF; }
  .toast-style.warning .left-side {
    background-color: #EF9134; }
  .toast-style .left-side {
    width: 60px;
    display: flex;
    align-items: center;
    justify-content: center; }
    .toast-style .left-side .icon .path1:before {
      color: white; }
    .toast-style .left-side .icon .path2:before {
      color: white; }
  .toast-style .right-side {
    width: calc(100% - 60px);
    padding: 20px; }
  .toast-style p {
    font-size: 16px;
    color: rgba(12, 12, 12, 0.7); }

.__react_component_tooltip {
  border-radius: 2px;
  font-weight: 500;
  width: auto; }
  .__react_component_tooltip.type-dark {
    background-color: #616161; }
  .__react_component_tooltip:after {
    content: none; }

.test-grid [class^="col"], .test-grid > [class*=" col"] {
  min-height: 200px;
  border: 2px solid gray;
  padding: 15px; }

.sign-page {
  background: #F8F8F8 url(/static/media/sign-bg.07badf04.svg) no-repeat center;
  background-size: auto;
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center; }
  .sign-page button {
    max-width: 100%; }

.sign-form {
  padding: 25px 80px 60px;
  width: 100%;
  background-color: #fff;
  border: 1px solid rgba(12, 12, 12, 0.1);
  border-radius: 4px; }
  .sign-form.sign-up {
    max-width: 920px; }
  .sign-form.sign-in {
    max-width: 570px; }

.sign-header {
  padding-bottom: 20px;
  border-bottom: 1px solid rgba(12, 12, 12, 0.1); }

.sign-body {
  padding-top: 40px; }

.upload-img {
  text-align: center;
  padding: 70px 100px; }

.image-list {
  display: grid;
  grid-gap: 20px; }
  .image-list:not(.with-right-btn) {
    grid-template-columns: repeat(5, 1fr);
    grid-auto-rows: 152px; }
  .image-list.with-right-btn {
    grid-template-columns: repeat(5, 1fr);
    grid-auto-rows: 122px; }
    .image-list.with-right-btn .info {
      position: absolute;
      right: 0;
      top: 0; }
  .image-list.cursor-pointer .image-list-item {
    cursor: pointer; }
  .image-list.selected .image-list-item:not(.upload) {
    border-color: #179DFF; }
    .image-list.selected .image-list-item:not(.upload):after {
      content: "";
      width: 100%;
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      background-color: rgba(0, 0, 0, 0.4);
      opacity: 0;
      -webkit-transition: all .3s;
      transition: all .3s; }
    .image-list.selected .image-list-item:not(.upload).selected-item:after {
      opacity: 1; }
    .image-list.selected .image-list-item:not(.upload).selected-item:before {
      content: "";
      font-family: "icomoon";
      color: #fff;
      font-size: 32px;
      position: absolute;
      z-index: 1;
      left: 50%;
      top: 50%;
      -webkit-transform: translateY(-50%) translateX(-50%);
              transform: translateY(-50%) translateX(-50%); }

.image-list-item {
  border-radius: 4px;
  position: relative;
  border: 2px solid transparent;
  -webkit-transition: all .3s;
  transition: all .3s; }
  .image-list-item.default-image {
    background-color: #EEEEEE;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 48px;
    color: rgba(12, 12, 12, 0.1);
    background-image: -webkit-gradient(linear, left top, right top, from(#EEEEEE), color-stop(20%, #f6f7f8), color-stop(60%, #EEEEEE), to(#EEEEEE));
    background-image: -webkit-linear-gradient(left, #EEEEEE 0%, #f6f7f8 20%, #EEEEEE 60%, #EEEEEE 100%);
    background-image: linear-gradient(to right, #EEEEEE 0%, #f6f7f8 20%, #EEEEEE 60%, #EEEEEE 100%);
    background-repeat: no-repeat;
    background-size: 240px;
    position: relative;
    -webkit-animation: placeholderShimmer 1.5s forwards infinite linear;
            animation: placeholderShimmer 1.5s forwards infinite linear;
    will-change: transform; }
  .image-list-item.upload {
    border: 1px dashed rgba(12, 12, 12, 0.1);
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center; }
    .image-list-item.upload .icon {
      font-size: 42px;
      color: #179DFF; }
  .image-list-item:not(.upload):not(.default-image) {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center; }

.default-info {
  display: block;
  height: 21px;
  width: 21px;
  border-radius: 50%;
  background-color: rgba(12, 12, 12, 0.1); }

.image-list-item-action {
  height: 28px;
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 10px; }
  .image-list-item-action .icon {
    padding: 3px 4px; }
    .image-list-item-action .icon.icon-delete {
      cursor: pointer; }
      .image-list-item-action .icon.icon-delete [class^="path"]:before {
        color: #fff; }
  .image-list-item-action .icon, .image-list-item-action .icon [class^="path"]:before {
    color: rgba(255, 255, 255, 0.7); }

.bar-bottom {
  position: fixed;
  left: 210px;
  bottom: 0;
  right: 0;
  padding: 15px 0;
  z-index: 1; }
  .bar-bottom.blue-bar {
    background-color: #179DFF; }
    .bar-bottom.blue-bar button {
      display: flex;
      align-items: center;
      color: #fff;
      font-weight: 700;
      font-size: 16px;
      margin: 0 50px;
      cursor: pointer; }
      .bar-bottom.blue-bar button .icon span:before {
        color: #fff; }
  .bar-bottom.grey-bar {
    background-color: #eee;
    height: 75px; }

@-webkit-keyframes placeholderShimmer {
  0% {
    background-position: -200px 0;
    -webkit-transform: scale(1);
            transform: scale(1); }
  50% {
    -webkit-transform: scale(1.02);
            transform: scale(1.02); }
  100% {
    background-position: 200px 0;
    -webkit-transform: scale(1);
            transform: scale(1); } }

@keyframes placeholderShimmer {
  0% {
    background-position: -200px 0;
    -webkit-transform: scale(1);
            transform: scale(1); }
  50% {
    -webkit-transform: scale(1.02);
            transform: scale(1.02); }
  100% {
    background-position: 200px 0;
    -webkit-transform: scale(1);
            transform: scale(1); } }

@media (max-width: 768px) {
  body {
    font-size: 13px; } }

@media (max-width: 768px) {
  .container-sm {
    max-width: 100%;
    min-height: calc(100vh - 70px); } }

@media (max-width: 767px) {
  .mt-sm-0 {
    margin-top: 0px; }
  .mr-sm-0 {
    margin-right: 0px; }
  .mb-sm-0 {
    margin-bottom: 0px; }
  .ml-sm-0 {
    margin-left: 0px; }
  .my-sm-0 {
    margin-top: 0px;
    margin-bottom: 0px; }
  .mx-sm-0 {
    margin-left: 0px;
    margin-right: 0px; }
  .m-sm-0 {
    margin: 0px; }
  .pt-sm-0 {
    padding-top: 0px; }
  .pr-sm-0 {
    padding-right: 0px; }
  .pb-sm-0 {
    padding-bottom: 0px; }
  .pl-sm-0 {
    padding-left: 0px; }
  .py-sm-0 {
    padding-top: 0px;
    padding-bottom: 0px; }
  .px-sm-0 {
    padding-left: 0px;
    padding-right: 0px; }
  .p-sm-0 {
    padding: 0px; }
  .mt-sm-5 {
    margin-top: 5px; }
  .mr-sm-5 {
    margin-right: 5px; }
  .mb-sm-5 {
    margin-bottom: 5px; }
  .ml-sm-5 {
    margin-left: 5px; }
  .my-sm-5 {
    margin-top: 5px;
    margin-bottom: 5px; }
  .mx-sm-5 {
    margin-left: 5px;
    margin-right: 5px; }
  .m-sm-5 {
    margin: 5px; }
  .pt-sm-5 {
    padding-top: 5px; }
  .pr-sm-5 {
    padding-right: 5px; }
  .pb-sm-5 {
    padding-bottom: 5px; }
  .pl-sm-5 {
    padding-left: 5px; }
  .py-sm-5 {
    padding-top: 5px;
    padding-bottom: 5px; }
  .px-sm-5 {
    padding-left: 5px;
    padding-right: 5px; }
  .p-sm-5 {
    padding: 5px; }
  .mt-sm-10 {
    margin-top: 10px; }
  .mr-sm-10 {
    margin-right: 10px; }
  .mb-sm-10 {
    margin-bottom: 10px; }
  .ml-sm-10 {
    margin-left: 10px; }
  .my-sm-10 {
    margin-top: 10px;
    margin-bottom: 10px; }
  .mx-sm-10 {
    margin-left: 10px;
    margin-right: 10px; }
  .m-sm-10 {
    margin: 10px; }
  .pt-sm-10 {
    padding-top: 10px; }
  .pr-sm-10 {
    padding-right: 10px; }
  .pb-sm-10 {
    padding-bottom: 10px; }
  .pl-sm-10 {
    padding-left: 10px; }
  .py-sm-10 {
    padding-top: 10px;
    padding-bottom: 10px; }
  .px-sm-10 {
    padding-left: 10px;
    padding-right: 10px; }
  .p-sm-10 {
    padding: 10px; }
  .mt-sm-15 {
    margin-top: 15px; }
  .mr-sm-15 {
    margin-right: 15px; }
  .mb-sm-15 {
    margin-bottom: 15px; }
  .ml-sm-15 {
    margin-left: 15px; }
  .my-sm-15 {
    margin-top: 15px;
    margin-bottom: 15px; }
  .mx-sm-15 {
    margin-left: 15px;
    margin-right: 15px; }
  .m-sm-15 {
    margin: 15px; }
  .pt-sm-15 {
    padding-top: 15px; }
  .pr-sm-15 {
    padding-right: 15px; }
  .pb-sm-15 {
    padding-bottom: 15px; }
  .pl-sm-15 {
    padding-left: 15px; }
  .py-sm-15 {
    padding-top: 15px;
    padding-bottom: 15px; }
  .px-sm-15 {
    padding-left: 15px;
    padding-right: 15px; }
  .p-sm-15 {
    padding: 15px; }
  .mt-sm-20 {
    margin-top: 20px; }
  .mr-sm-20 {
    margin-right: 20px; }
  .mb-sm-20 {
    margin-bottom: 20px; }
  .ml-sm-20 {
    margin-left: 20px; }
  .my-sm-20 {
    margin-top: 20px;
    margin-bottom: 20px; }
  .mx-sm-20 {
    margin-left: 20px;
    margin-right: 20px; }
  .m-sm-20 {
    margin: 20px; }
  .pt-sm-20 {
    padding-top: 20px; }
  .pr-sm-20 {
    padding-right: 20px; }
  .pb-sm-20 {
    padding-bottom: 20px; }
  .pl-sm-20 {
    padding-left: 20px; }
  .py-sm-20 {
    padding-top: 20px;
    padding-bottom: 20px; }
  .px-sm-20 {
    padding-left: 20px;
    padding-right: 20px; }
  .p-sm-20 {
    padding: 20px; }
  .mt-sm-25 {
    margin-top: 25px; }
  .mr-sm-25 {
    margin-right: 25px; }
  .mb-sm-25 {
    margin-bottom: 25px; }
  .ml-sm-25 {
    margin-left: 25px; }
  .my-sm-25 {
    margin-top: 25px;
    margin-bottom: 25px; }
  .mx-sm-25 {
    margin-left: 25px;
    margin-right: 25px; }
  .m-sm-25 {
    margin: 25px; }
  .pt-sm-25 {
    padding-top: 25px; }
  .pr-sm-25 {
    padding-right: 25px; }
  .pb-sm-25 {
    padding-bottom: 25px; }
  .pl-sm-25 {
    padding-left: 25px; }
  .py-sm-25 {
    padding-top: 25px;
    padding-bottom: 25px; }
  .px-sm-25 {
    padding-left: 25px;
    padding-right: 25px; }
  .p-sm-25 {
    padding: 25px; }
  .mt-sm-30 {
    margin-top: 30px; }
  .mr-sm-30 {
    margin-right: 30px; }
  .mb-sm-30 {
    margin-bottom: 30px; }
  .ml-sm-30 {
    margin-left: 30px; }
  .my-sm-30 {
    margin-top: 30px;
    margin-bottom: 30px; }
  .mx-sm-30 {
    margin-left: 30px;
    margin-right: 30px; }
  .m-sm-30 {
    margin: 30px; }
  .pt-sm-30 {
    padding-top: 30px; }
  .pr-sm-30 {
    padding-right: 30px; }
  .pb-sm-30 {
    padding-bottom: 30px; }
  .pl-sm-30 {
    padding-left: 30px; }
  .py-sm-30 {
    padding-top: 30px;
    padding-bottom: 30px; }
  .px-sm-30 {
    padding-left: 30px;
    padding-right: 30px; }
  .p-sm-30 {
    padding: 30px; }
  .mt-sm-35 {
    margin-top: 35px; }
  .mr-sm-35 {
    margin-right: 35px; }
  .mb-sm-35 {
    margin-bottom: 35px; }
  .ml-sm-35 {
    margin-left: 35px; }
  .my-sm-35 {
    margin-top: 35px;
    margin-bottom: 35px; }
  .mx-sm-35 {
    margin-left: 35px;
    margin-right: 35px; }
  .m-sm-35 {
    margin: 35px; }
  .pt-sm-35 {
    padding-top: 35px; }
  .pr-sm-35 {
    padding-right: 35px; }
  .pb-sm-35 {
    padding-bottom: 35px; }
  .pl-sm-35 {
    padding-left: 35px; }
  .py-sm-35 {
    padding-top: 35px;
    padding-bottom: 35px; }
  .px-sm-35 {
    padding-left: 35px;
    padding-right: 35px; }
  .p-sm-35 {
    padding: 35px; }
  .mt-sm-40 {
    margin-top: 40px; }
  .mr-sm-40 {
    margin-right: 40px; }
  .mb-sm-40 {
    margin-bottom: 40px; }
  .ml-sm-40 {
    margin-left: 40px; }
  .my-sm-40 {
    margin-top: 40px;
    margin-bottom: 40px; }
  .mx-sm-40 {
    margin-left: 40px;
    margin-right: 40px; }
  .m-sm-40 {
    margin: 40px; }
  .pt-sm-40 {
    padding-top: 40px; }
  .pr-sm-40 {
    padding-right: 40px; }
  .pb-sm-40 {
    padding-bottom: 40px; }
  .pl-sm-40 {
    padding-left: 40px; }
  .py-sm-40 {
    padding-top: 40px;
    padding-bottom: 40px; }
  .px-sm-40 {
    padding-left: 40px;
    padding-right: 40px; }
  .p-sm-40 {
    padding: 40px; }
  .mt-sm-45 {
    margin-top: 45px; }
  .mr-sm-45 {
    margin-right: 45px; }
  .mb-sm-45 {
    margin-bottom: 45px; }
  .ml-sm-45 {
    margin-left: 45px; }
  .my-sm-45 {
    margin-top: 45px;
    margin-bottom: 45px; }
  .mx-sm-45 {
    margin-left: 45px;
    margin-right: 45px; }
  .m-sm-45 {
    margin: 45px; }
  .pt-sm-45 {
    padding-top: 45px; }
  .pr-sm-45 {
    padding-right: 45px; }
  .pb-sm-45 {
    padding-bottom: 45px; }
  .pl-sm-45 {
    padding-left: 45px; }
  .py-sm-45 {
    padding-top: 45px;
    padding-bottom: 45px; }
  .px-sm-45 {
    padding-left: 45px;
    padding-right: 45px; }
  .p-sm-45 {
    padding: 45px; }
  .mt-sm-50 {
    margin-top: 50px; }
  .mr-sm-50 {
    margin-right: 50px; }
  .mb-sm-50 {
    margin-bottom: 50px; }
  .ml-sm-50 {
    margin-left: 50px; }
  .my-sm-50 {
    margin-top: 50px;
    margin-bottom: 50px; }
  .mx-sm-50 {
    margin-left: 50px;
    margin-right: 50px; }
  .m-sm-50 {
    margin: 50px; }
  .pt-sm-50 {
    padding-top: 50px; }
  .pr-sm-50 {
    padding-right: 50px; }
  .pb-sm-50 {
    padding-bottom: 50px; }
  .pl-sm-50 {
    padding-left: 50px; }
  .py-sm-50 {
    padding-top: 50px;
    padding-bottom: 50px; }
  .px-sm-50 {
    padding-left: 50px;
    padding-right: 50px; }
  .p-sm-50 {
    padding: 50px; }
  .mt-sm-55 {
    margin-top: 55px; }
  .mr-sm-55 {
    margin-right: 55px; }
  .mb-sm-55 {
    margin-bottom: 55px; }
  .ml-sm-55 {
    margin-left: 55px; }
  .my-sm-55 {
    margin-top: 55px;
    margin-bottom: 55px; }
  .mx-sm-55 {
    margin-left: 55px;
    margin-right: 55px; }
  .m-sm-55 {
    margin: 55px; }
  .pt-sm-55 {
    padding-top: 55px; }
  .pr-sm-55 {
    padding-right: 55px; }
  .pb-sm-55 {
    padding-bottom: 55px; }
  .pl-sm-55 {
    padding-left: 55px; }
  .py-sm-55 {
    padding-top: 55px;
    padding-bottom: 55px; }
  .px-sm-55 {
    padding-left: 55px;
    padding-right: 55px; }
  .p-sm-55 {
    padding: 55px; }
  .mt-sm-60 {
    margin-top: 60px; }
  .mr-sm-60 {
    margin-right: 60px; }
  .mb-sm-60 {
    margin-bottom: 60px; }
  .ml-sm-60 {
    margin-left: 60px; }
  .my-sm-60 {
    margin-top: 60px;
    margin-bottom: 60px; }
  .mx-sm-60 {
    margin-left: 60px;
    margin-right: 60px; }
  .m-sm-60 {
    margin: 60px; }
  .pt-sm-60 {
    padding-top: 60px; }
  .pr-sm-60 {
    padding-right: 60px; }
  .pb-sm-60 {
    padding-bottom: 60px; }
  .pl-sm-60 {
    padding-left: 60px; }
  .py-sm-60 {
    padding-top: 60px;
    padding-bottom: 60px; }
  .px-sm-60 {
    padding-left: 60px;
    padding-right: 60px; }
  .p-sm-60 {
    padding: 60px; }
  .mt-sm-65 {
    margin-top: 65px; }
  .mr-sm-65 {
    margin-right: 65px; }
  .mb-sm-65 {
    margin-bottom: 65px; }
  .ml-sm-65 {
    margin-left: 65px; }
  .my-sm-65 {
    margin-top: 65px;
    margin-bottom: 65px; }
  .mx-sm-65 {
    margin-left: 65px;
    margin-right: 65px; }
  .m-sm-65 {
    margin: 65px; }
  .pt-sm-65 {
    padding-top: 65px; }
  .pr-sm-65 {
    padding-right: 65px; }
  .pb-sm-65 {
    padding-bottom: 65px; }
  .pl-sm-65 {
    padding-left: 65px; }
  .py-sm-65 {
    padding-top: 65px;
    padding-bottom: 65px; }
  .px-sm-65 {
    padding-left: 65px;
    padding-right: 65px; }
  .p-sm-65 {
    padding: 65px; }
  .mt-sm-70 {
    margin-top: 70px; }
  .mr-sm-70 {
    margin-right: 70px; }
  .mb-sm-70 {
    margin-bottom: 70px; }
  .ml-sm-70 {
    margin-left: 70px; }
  .my-sm-70 {
    margin-top: 70px;
    margin-bottom: 70px; }
  .mx-sm-70 {
    margin-left: 70px;
    margin-right: 70px; }
  .m-sm-70 {
    margin: 70px; }
  .pt-sm-70 {
    padding-top: 70px; }
  .pr-sm-70 {
    padding-right: 70px; }
  .pb-sm-70 {
    padding-bottom: 70px; }
  .pl-sm-70 {
    padding-left: 70px; }
  .py-sm-70 {
    padding-top: 70px;
    padding-bottom: 70px; }
  .px-sm-70 {
    padding-left: 70px;
    padding-right: 70px; }
  .p-sm-70 {
    padding: 70px; }
  .mt-sm-75 {
    margin-top: 75px; }
  .mr-sm-75 {
    margin-right: 75px; }
  .mb-sm-75 {
    margin-bottom: 75px; }
  .ml-sm-75 {
    margin-left: 75px; }
  .my-sm-75 {
    margin-top: 75px;
    margin-bottom: 75px; }
  .mx-sm-75 {
    margin-left: 75px;
    margin-right: 75px; }
  .m-sm-75 {
    margin: 75px; }
  .pt-sm-75 {
    padding-top: 75px; }
  .pr-sm-75 {
    padding-right: 75px; }
  .pb-sm-75 {
    padding-bottom: 75px; }
  .pl-sm-75 {
    padding-left: 75px; }
  .py-sm-75 {
    padding-top: 75px;
    padding-bottom: 75px; }
  .px-sm-75 {
    padding-left: 75px;
    padding-right: 75px; }
  .p-sm-75 {
    padding: 75px; }
  .mt-sm-80 {
    margin-top: 80px; }
  .mr-sm-80 {
    margin-right: 80px; }
  .mb-sm-80 {
    margin-bottom: 80px; }
  .ml-sm-80 {
    margin-left: 80px; }
  .my-sm-80 {
    margin-top: 80px;
    margin-bottom: 80px; }
  .mx-sm-80 {
    margin-left: 80px;
    margin-right: 80px; }
  .m-sm-80 {
    margin: 80px; }
  .pt-sm-80 {
    padding-top: 80px; }
  .pr-sm-80 {
    padding-right: 80px; }
  .pb-sm-80 {
    padding-bottom: 80px; }
  .pl-sm-80 {
    padding-left: 80px; }
  .py-sm-80 {
    padding-top: 80px;
    padding-bottom: 80px; }
  .px-sm-80 {
    padding-left: 80px;
    padding-right: 80px; }
  .p-sm-80 {
    padding: 80px; }
  .mt-sm-85 {
    margin-top: 85px; }
  .mr-sm-85 {
    margin-right: 85px; }
  .mb-sm-85 {
    margin-bottom: 85px; }
  .ml-sm-85 {
    margin-left: 85px; }
  .my-sm-85 {
    margin-top: 85px;
    margin-bottom: 85px; }
  .mx-sm-85 {
    margin-left: 85px;
    margin-right: 85px; }
  .m-sm-85 {
    margin: 85px; }
  .pt-sm-85 {
    padding-top: 85px; }
  .pr-sm-85 {
    padding-right: 85px; }
  .pb-sm-85 {
    padding-bottom: 85px; }
  .pl-sm-85 {
    padding-left: 85px; }
  .py-sm-85 {
    padding-top: 85px;
    padding-bottom: 85px; }
  .px-sm-85 {
    padding-left: 85px;
    padding-right: 85px; }
  .p-sm-85 {
    padding: 85px; }
  .mt-sm-90 {
    margin-top: 90px; }
  .mr-sm-90 {
    margin-right: 90px; }
  .mb-sm-90 {
    margin-bottom: 90px; }
  .ml-sm-90 {
    margin-left: 90px; }
  .my-sm-90 {
    margin-top: 90px;
    margin-bottom: 90px; }
  .mx-sm-90 {
    margin-left: 90px;
    margin-right: 90px; }
  .m-sm-90 {
    margin: 90px; }
  .pt-sm-90 {
    padding-top: 90px; }
  .pr-sm-90 {
    padding-right: 90px; }
  .pb-sm-90 {
    padding-bottom: 90px; }
  .pl-sm-90 {
    padding-left: 90px; }
  .py-sm-90 {
    padding-top: 90px;
    padding-bottom: 90px; }
  .px-sm-90 {
    padding-left: 90px;
    padding-right: 90px; }
  .p-sm-90 {
    padding: 90px; }
  .mt-sm-95 {
    margin-top: 95px; }
  .mr-sm-95 {
    margin-right: 95px; }
  .mb-sm-95 {
    margin-bottom: 95px; }
  .ml-sm-95 {
    margin-left: 95px; }
  .my-sm-95 {
    margin-top: 95px;
    margin-bottom: 95px; }
  .mx-sm-95 {
    margin-left: 95px;
    margin-right: 95px; }
  .m-sm-95 {
    margin: 95px; }
  .pt-sm-95 {
    padding-top: 95px; }
  .pr-sm-95 {
    padding-right: 95px; }
  .pb-sm-95 {
    padding-bottom: 95px; }
  .pl-sm-95 {
    padding-left: 95px; }
  .py-sm-95 {
    padding-top: 95px;
    padding-bottom: 95px; }
  .px-sm-95 {
    padding-left: 95px;
    padding-right: 95px; }
  .p-sm-95 {
    padding: 95px; }
  .mt-sm-100 {
    margin-top: 100px; }
  .mr-sm-100 {
    margin-right: 100px; }
  .mb-sm-100 {
    margin-bottom: 100px; }
  .ml-sm-100 {
    margin-left: 100px; }
  .my-sm-100 {
    margin-top: 100px;
    margin-bottom: 100px; }
  .mx-sm-100 {
    margin-left: 100px;
    margin-right: 100px; }
  .m-sm-100 {
    margin: 100px; }
  .pt-sm-100 {
    padding-top: 100px; }
  .pr-sm-100 {
    padding-right: 100px; }
  .pb-sm-100 {
    padding-bottom: 100px; }
  .pl-sm-100 {
    padding-left: 100px; }
  .py-sm-100 {
    padding-top: 100px;
    padding-bottom: 100px; }
  .px-sm-100 {
    padding-left: 100px;
    padding-right: 100px; }
  .p-sm-100 {
    padding: 100px; } }

@media (max-width: 767px) {
  .upload-img {
    padding: 60px 0; }
  .box {
    border: none; }
  .folder-group {
    grid-template-columns: repeat(2, calc(50% - 8px)); }
  .img-card-list .img-card {
    width: 100%; }
  .empty-page-3 {
    min-height: calc(100vh - 300px);
    height: auto; }
    .empty-page-3 .pb-100 {
      padding-bottom: 0; } }

@media (max-width: 499px) {
  .folder-group {
    grid-template-columns: repeat(1, 100%); } }

@media (max-width: 359px) {
  .img-card-top .img {
    width: 75px;
    height: 75px; } }

@media (max-width: 1023px) {
  .sign-form {
    padding: 20px 40px 30px; }
    .sign-form.sign-up {
      max-width: 740px; } }

@media (max-width: 767px) {
  .sign-form {
    padding: 20px;
    min-height: 100vh; }
    .sign-form.sign-up, .sign-form.sign-in {
      max-width: 100%; }
  .sign-form-inner {
    max-width: 440px;
    margin: 0 auto;
    padding-top: 30px; }
  .sign-body {
    padding-top: 20px; } }

@media (max-width: 1199px) {
  .bar-bottom {
    left: 170px; } }

@media (max-width: 1023px) {
  .bar-bottom {
    left: 0; } }

@media (max-width: 767px) {
  .image-list {
    grid-gap: 10px; }
    .image-list:not(.with-right-btn) {
      grid-template-columns: repeat(4, 1fr);
      grid-auto-rows: 130px; }
    .image-list.with-right-btn {
      grid-template-columns: repeat(4, 1fr);
      grid-auto-rows: 100px; }
  .bar-bottom > .flex {
    justify-content: space-between; } }

@media (max-width: 499px) {
  .image-list:not(.with-right-btn) {
    grid-template-columns: repeat(3, 1fr);
    grid-auto-rows: 90px; }
  .image-list.with-right-btn {
    grid-template-columns: repeat(3, 1fr);
    grid-auto-rows: 80px; } }

@media (max-width: 359px) {
  .image-list.with-right-btn {
    grid-template-columns: repeat(2, 1fr);
    grid-auto-rows: 80px; } }

@media (max-width: 1199px) and (min-width: 1024px) {
  header {
    padding-left: 170px; }
  .page-wrapper {
    padding-left: 170px; }
  .sidebar {
    width: 170px; }
    .sidebar .menu li a {
      padding: 10px 10px 10px 50px;
      font-size: 14px; }
    .sidebar .menu .icon {
      left: 13px; } }

@media (max-width: 1023px) {
  .container {
    max-width: 100%; }
  header {
    padding-left: 0; }
    header .right-side .role, header .right-side a {
      display: none; }
  .header-inner {
    flex-direction: row-reverse;
    padding-left: 60px; }
    .header-inner .right-side {
      margin-right: auto;
      margin-left: 0; }
  .page-wrapper {
    padding-left: 0; }
  .sidebar {
    -webkit-transform: translateX(-100%);
            transform: translateX(-100%);
    -webkit-transition: -webkit-transform .3s;
    transition: -webkit-transform .3s;
    transition: transform .3s;
    transition: transform .3s, -webkit-transform .3s; }
    .sidebar.open {
      -webkit-transform: translateX(0);
              transform: translateX(0);
      z-index: 4; }
      .sidebar.open .sidebar-left-btn {
        opacity: 1;
        color: #fff;
        display: block; }
    .sidebar .logout {
      display: block; }
  .sidebar-right {
    width: 360px;
    -webkit-transform: translateX(360px);
            transform: translateX(360px);
    padding: 45px; }
  .sidebar-left-btn {
    display: none; }
  .sidebar-open-btn {
    display: block; } }

@media (max-width: 767px) {
  header {
    background-color: #fff; }
  .page-wrapper {
    background-color: #fff; }
  .sidebar {
    width: 100%; }
    .sidebar.open {
      -webkit-transform: translateX(0);
              transform: translateX(0);
      left: 0; }
      .sidebar.open .sidebar-left-btn {
        opacity: 1;
        right: 15px;
        color: #000; }
  .sidebar-right {
    width: 100%;
    -webkit-transform: translateX(100%);
            transform: translateX(100%);
    padding: 30px; }
  .sidebar-right-btn {
    display: block; }
  .sidebar-meta {
    justify-content: center; } }

@media (max-width: 767px) {
  .stepper .stepper-header {
    padding: 30px 30px 20px; }
  .stepper .stepper-header-top {
    display: none; }
  .stepper .stepper-header-bottom {
    padding-top: 0; } }

@media (max-width: 767px) {
  .tab-style.folder-mobile {
    flex-direction: column;
    border: none; }
    .tab-style.folder-mobile li {
      width: 100%;
      padding: 10px 20px;
      margin-bottom: 15px;
      border: 1px solid #26BE7C;
      background-color: rgba(38, 190, 124, 0.04);
      border-radius: 4px;
      color: #0c0c0c;
      text-align: left;
      display: flex;
      align-items: center; }
      .tab-style.folder-mobile li .icon {
        margin-right: 30px;
        display: block; }
      .tab-style.folder-mobile li.active {
        background-color: #26BE7C;
        color: #fff; }
        .tab-style.folder-mobile li.active .icon [class^="path"]:before {
          color: #fff; } }

@media screen and (max-width: 1023px) {
  .react-datepicker {
    padding: 0; }
  .react-datepicker__day-name,
  .react-datepicker__day,
  .react-datepicker__time-name {
    width: 30px;
    height: 30px;
    line-height: 30px;
    font-size: 12px; }
  .react-datepicker__current-month {
    padding-bottom: 10px; }
  .react-datepicker__navigation {
    top: 5px; }
  .react-datepicker__navigation.react-datepicker__navigation--previous {
    left: 30px; }
  .react-datepicker__navigation.react-datepicker__navigation--next {
    right: 30px; } }

@media (max-width: 767px) {
  .align-right .react-datepicker-popper {
    left: inherit !important;
    right: inherit !important; } }

@media (max-width: 499px) {
  .Toastify__toast-container {
    padding: 0 10px; }
  .Toastify__toast {
    max-width: 100%; } }

@media screen and (max-height: 670px) {
  .react-datepicker {
    padding: 0; }
  .react-datepicker__day-name,
  .react-datepicker__day,
  .react-datepicker__time-name {
    width: 25px;
    height: 25px;
    line-height: 25px;
    font-size: 12px; }
  .react-datepicker__current-month {
    padding-bottom: 5px; }
  .react-datepicker__navigation {
    top: 5px; }
  .react-datepicker__navigation.react-datepicker__navigation--previous {
    left: 30px; }
  .react-datepicker__navigation.react-datepicker__navigation--next {
    right: 30px; } }

@media (max-width: 499px) {
  .modal.modal-md .styles_modal__gNwvD, .modal.modal-sm .styles_modal__gNwvD {
    padding: 20px; } }

