@media (max-width: $screen-lg-max) and (min-width: $screen-md) {
  header {
    padding-left: 170px;
  }
  .page-wrapper {
    padding-left: 170px;
  }
  .sidebar {
    width: 170px;

    .menu {
      li {
        a {
          padding: 10px 10px 10px 50px;
          font-size: 14px;
        }
      }

      .icon {
        left: 13px;
      }
    }
  }
}

@media (max-width: $screen-md-max) {
  .container {
    max-width: 100%;
  }

  header {
    padding-left: 0;

    .right-side {
      .role, a {
        display: none;
      }
    }
  }
  .header-inner {
    flex-direction: row-reverse;
    padding-left: 60px;

    .right-side {
      margin-right: auto;
      margin-left: 0;
    }
  }

  .page-wrapper {
    padding-left: 0;
  }

  .sidebar {
    transform: translateX(-100%);
    transition: transform .3s;

    &.open {
      transform: translateX(0);
      z-index: 4;

      .sidebar-left-btn {
        opacity: 1;
        color: $color-white;
        display: block;
      }
    }

    .logout {
      display: block;
    }
  }
  .sidebar-right {
    width: 360px;
    transform: translateX(360px);
    padding:  45px;
  }

  .sidebar-left-btn {
    display: none;
  }

  .sidebar-open-btn {
    display: block;
  }

}

@media (max-width: $screen-sm-max) {

  header {
    background-color: $color-white;
  }

  .page-wrapper {
    background-color: #fff;
  }

  .sidebar {
    width: 100%;

    &.open {
      transform: translateX(0);
      left: 0;

      .sidebar-left-btn {
        opacity: 1;
        right: 15px;
        color: #000;
      }
    }
  }
  .sidebar-right {
    width: 100%;
    transform: translateX(100%);
    padding: 30px;
  }
  .sidebar-right-btn {
    display: block;
  }
  .sidebar-meta {
    justify-content: center;
  }


}